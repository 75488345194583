import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ChartService } from 'src/app/components/charts/chart.service';

@Component({
  selector: 'app-quantity-rejected',
  templateUrl: './quantity-rejected.component.html',
  styleUrls: ['./quantity-rejected.component.scss'],
})
export class QuantityRejectedComponent implements OnInit, OnDestroy {
  selectedField!: string;

  prov: any;
  day: any;
  week: any;
  month: any;
  placeHolder: string = 'Seleziona una provincia';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService
  ) { }

  ngOnInit(): void {
    this.getTransformerMunicipality();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.getTransformerQuantityData();
  }

  getTransformerMunicipality(): void {
    this._chartService.getTransformerMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        if (r.data.length > 0) {
          this.prov = r.data;
          this.selectedField = r.data[0]?.id;
          this.getTransformerQuantityData();
        } else {
          this.placeHolder = 'Nessun dato';
        }
      });
  }

  getTransformerQuantityData(): void {
    this._chartService
      .getTransformerQuantityData(this.selectedField)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.day = r.data.day;
        this.week = r.data.week;
        this.month = r.data.month;
      });
  }
}
