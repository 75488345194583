import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { ComponentService } from '../component.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CommonService } from '../common.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-myPlantations',
  templateUrl: './myPlantations.component.html',
  styleUrls: ['./myPlantations.component.scss'],
})
export class MyPlantationsComponent implements OnInit, OnDestroy {
  @ViewChild('editForm') editForm!: ElementRef;

  currentTemplate: string = 'plants';
  placeOfPlantStr: string = '';
  showFormPlants: boolean = false;
  currentOlant: any;
  plantId: any;
  goBack: boolean = false;
  plants: any[] = [];

  isMobileWithTablet$: Observable<boolean>;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly _commonService: CommonService,
    private readonly _layoutService: LayoutService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.isMobileWithTablet$ = this._layoutService.isMobileWithTablet$;
  }

  ngOnInit(): void {
    this.getAllProducerPlants();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goTOAddPlant(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.currentTemplate = 'add-plant-form';
  }

  getAllProducerPlants(): void {
    this._componentService
      .getAllProducerPlants()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        if (r.data !== null) {
          this.plants = r.data;
          this.showFormPlants = false;
          this.placeOfPlantStr = '';
        }
      });
  }

  onOutputChild(messaggio: string): void {
    this.getAllProducerPlants();
    this.currentTemplate =
      messaggio == 'reset' || messaggio == 'ok' ? 'plants' : messaggio;
  }

  showFormMyPlants(plant: any, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.plants.forEach((p) => (p.selected = false));
    if (plant) {
      this.plantId = plant.id;
      this.placeOfPlantStr = this.getPlaceOfPlantStr(plant);
      plant.selected = true;
      this.currentOlant = plant.id;

      // Aggiorna la vista e esegui lo scroll una volta che le modifiche sono state applicate
      this.cdr.detectChanges(); // Forza l'aggiornamento della vista
      this.showFormPlants = true;
      this.scrollToForm();
      this.goBack = true;
    }
  }

  goBackFn(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.currentTemplate = 'plants';
    this.goBack = false;
    this.showFormPlants = false;
    this.placeOfPlantStr = '';
    this.plants.forEach((p) => (p.selected = false));
  }

  scrollToForm(): void {
    const element = document.getElementById('editForm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.warn('Elemento editForm non trovato');
    }
  }

  private getPlaceOfPlantStr(plant: any): string {
    let placeOfPlantStr = '';

    if (plant?.municipality_name) {
      placeOfPlantStr += plant.municipality_name;
      if (plant?.province_code) {
        placeOfPlantStr += `, ${plant.province_code}`;
      }
    }

    return placeOfPlantStr;
  }
}
