<div *ngIf="role_id !== '4'">
  <app-feedback [showTitle]="true"></app-feedback>
</div>

<ng-container *ngIf="role_id === '4'">
  <div class="feedback-list-container">
    <div class="title-header">Feedback</div>
    <nz-tabset>
      <!-- Tab 1: Consumatori -->
      <nz-tab nzTitle="Consumatori">
        <app-feedback-consumer></app-feedback-consumer>
      </nz-tab>
  
      <!-- Tab 2: Operatori di filiera -->
      <nz-tab nzTitle="Operatori di filiera">
        <div class="operatori-tab-content">
          <app-feedback [showTitle]="false"></app-feedback>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
</ng-container>
