<div class="trasformer-home1">
  <div class="row mx-0">
    <!-- Left container (container sinistra) -->
    <div class="d-flex flex-column col-12 col-lg-8 gap-3 gap-lg-4 px-0 mb-3 mb-lg-0 ps-lg-0 pe-lg-4">
      <div class="most-used-activities-container">
        <app-most-used-activities [favoriteActivities]="favoriteActivities"></app-most-used-activities>
      </div>

      <!-- da modificare la condizione di ngif con il nuovo widget -->
      <div class="d-flex flex-column flex-lg-row gap-3 gap-lg-4">
        <div class="card bg-quantity bd-rad col-12 col-lg-5"
          *ngIf="widget.includes('app-quantity-rejected'); else noData">
          <div class="card-body">
            <app-actor-waste-used-widget></app-actor-waste-used-widget>
          </div>
        </div>
        <div class="d-flex flex-fill gap-3 gap-lg-4">
          <div class="card color-energy bd-rad w-100" *ngIf="widget.includes('app-consumed-energy'); else noData">
            <div class="card-body bg-energy">
              <app-consumed-energy></app-consumed-energy>
            </div>
          </div>
          <div class="card bd-rad color-water water-chart w-100"
            *ngIf="widget.includes('app-widget-water-transformer'); else noData">
            <div class="card-body bg-water bd-rad">
              <app-widget-water-transformer></app-widget-water-transformer>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="bd-rad h-100" *ngIf="widget.includes('app-latestShipments'); else noData">
          <app-latestShipments [actor]="'selection_user_id'"></app-latestShipments>
        </div>
      </div>
      <div>
        <div class="card bd-rad bg-color distr-lot" *ngIf="widget.includes('app-distributor-lots'); else noData">
          <div class="card-body">
            <app-distributor-lots [actor]="5"></app-distributor-lots>
          </div>
        </div>
      </div>
      <div>
        <div class="card bd-rad bg-color products-coming-soon h-100"
          *ngIf="widget.includes('app-widget-products-coming-soon'); else noData">
          <div class="card-body">
            <app-widget-products-coming-soon></app-widget-products-coming-soon>
          </div>
        </div>
      </div>
    </div>

    <!-- Right container (container destra) -->
    <div class="d-flex flex-column col-12 col-lg-4 gap-3 gap-lg-4 px-0">
      <div>
        <div class="card bd-rad bg-color widget-in-stock h-100"
          *ngIf="widget.includes('app-widget-in-stock'); else noData">
          <div class="card-body">
            <app-widget-in-stock></app-widget-in-stock>
          </div>
        </div>
      </div>
      <div>
        <div class="card h-100 bd-rad bg-quantity pb-0">
          <div class="card-body">
            <app-batch-waste-used></app-batch-waste-used>
          </div>
        </div>
      </div>
      <div>
        <div class="card bd-rad bg-feedback h-100" *ngIf="widget.includes('feedback'); else noData">
          <div class="card-body">
            <app-widget-feedback></app-widget-feedback>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="card bd-rad bg-no-data h-100">
    <div class="card-body">
      <app-no-data></app-no-data>
    </div>
  </div>
</ng-template>
