import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(private http: HttpClient) {}

  logClick(data: any) {
    let sessionId = localStorage.getItem('session_id');
    let newData = {
      page_url: data.url,
      widget_name: data.clickOn,
      session_id: sessionId,
    };

    return this.http.post<any>(
      `${environment.api}/log-interaction-engine-data`,
      newData
    );
  }

  interacionEngineSessionData(data: any) {
    return this.http.post<any>(
      `${environment.api}/log-interaction-engine-session-info`,
      data
    );
  }
}
