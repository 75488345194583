<div class="actor-waste-used-widget">
  <div class="d-flex justify-content-between align-items-center">
    <h4 class="header-widget">Quantità scartata</h4>
    <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
      <img src="/assets/img/arrow.svg" alt="arrow-explode" />
    </span>
  </div>

  <div class="content">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column align-items-center">
        <span class="fs-12">Oggi</span>
        <span class="weight-value">{{ wasteInfos?.day ?? '0' }}<span>kg</span></span>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <span class="fs-12">Settimana</span>
        <span class="weight-value">{{ wasteInfos?.week ?? '0' }}<span>kg</span></span>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <span class="fs-12">Mensile</span>
        <span class="weight-value">{{ wasteInfos?.month ?? '0' }}<span>kg</span></span>
      </div>
    </div>
  </div>
</div>