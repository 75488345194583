<div class="feedback-consumer-tab">
  <div class="feedback-consumer-tab__header">
    <div class="title-header">Prodotti più venduti</div>
    <div class="product-list">
      <div *ngFor="let product of productsList" class="product-feedback-card" (click)="selectCard(product)"
        [ngClass]="{'selected': isSelected(product)}" onkeypress="">
        <img [src]="getIcon(product.transformation_name)" class="product-feedback-card-img"
          [alt]="getIcon(product.transformation_name)">
        <p class="product-feedback-card-title"> {{ product.product_name }} </p>
        <p class="product-feedback-card-subtitle"> {{ product.producer }} </p>
      </div>
    </div>
  </div>
  <div class="feedback-consumer-tab__content" *ngIf="selectedProduct; else noData">
    <div class="card-list-header">
      <div class="row align-items-center mb-3">
        <div class="col-12 col-lg-5 mb-2 mb-lg-0 d-flex flex-column">
          <span class="card-list-header__name">{{ selectedProduct.product_name }}</span>
          <span class="card-list-header__producer">{{ selectedProduct.producer }}</span>
        </div>
        <div [formGroup]="feedbackForm" class="col-12 col-lg-7 d-flex align-items-center justify-content-lg-end">
          <div class="select-gender">
            <nz-select class="select-gender" nzPlaceHolder="Gender" nzBorderless formControlName="gender"
              (ngModelChange)="getFeedbackOnProduct()">
              <nz-option nzValue="F" nzLabel="F"></nz-option>
              <nz-option nzValue="M" nzLabel="M"></nz-option>
              <nz-option nzValue="" nzLabel="Tutti"></nz-option>
            </nz-select>
          </div>
          <div class="d-flex gap-3">
            <nz-select class="age-interval" nzPlaceHolder="Da" formControlName="startAge"
              (ngModelChange)="updateAgeRange()">
              <nz-option *ngFor="let age of startAgeOptions" [nzValue]="age" [nzLabel]="age"></nz-option>
            </nz-select>
            <nz-select class="age-interval" nzPlaceHolder="A" formControlName="endAge"
              (ngModelChange)="updateAgeRange()">
              <nz-option *ngFor="let age of endAgeOptions" [nzValue]="age" [nzLabel]="age"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="consumerFeedbackList?.length; else noData">
      <div class="feedback row mx-0" *ngFor="let item of consumerFeedbackList">
        <!-- Feedback Item Details -->
        <div class="col-12 ps-0">
          <div class="d-flex align-items-start align-items-lg-center flex-column flex-lg-row pb-4">
            <div class="d-flex flex-row align-items-center">
              <div class="img">
                <img src="../../../../../assets/icon/consumer-user-feedback.svg" alt="Consumatore generico">
              </div>
              <div class="name">
                Età: {{ item?.age }}<br />
                Genere: {{ item.gender }}
              </div>
            </div>
            <div class="circle"></div>
          </div>
          <div
            class="m-bottom d-flex align-items-center flex-row-reverse flex-lg-row justify-content-end justify-content-lg-start">
            <div class="vote">
              <nz-rate [ngModel]="item.rating" nzAllowHalf nzDisabled="true"></nz-rate>
            </div>
            <span class="title">{{ item.title }}</span>
          </div>
          <div class="m-bottom">
            <span class="date">
              Feedback lasciato il
              {{ item.created_at | date:'dd' }}
              {{ item.created_at | date:'MMMM' | capitalizeFirst}}
              {{ item.created_at | date:'yyyy' }}
            </span>
          </div>
          <div>
            <p class="text">{{ item.feedback }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #noData>
    <span class="list-no-data">Nessun dato</span>
  </ng-template>
</div>
