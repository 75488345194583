<div class="container1">
  <div class="row mx-0">
    <!-- First Widget mobile (widget alert only Mobile/Tablet) -->
    <div class="d-block d-lg-none col-12 mb-3 px-0">
      <div class="card bd-rad box-alert">
        <div class="card-body" id="card-fixed-height">
          <app-alert></app-alert>
        </div>
      </div>
    </div>

    <!-- Left container (container sinistra) -->
    <div class="d-flex flex-column col-12 col-lg-8 gap-3 gap-lg-4 px-0 mb-3 mb-lg-0 ps-lg-0 pe-lg-4">
      <!-- First Row -->
      <div class="most-used-activities-container">
        <app-most-used-activities [favoriteActivities]="favoriteActivities"></app-most-used-activities>
      </div>

      <!-- Second Row -->
      <div class="d-flex flex-column flex-lg-row gap-3 gap-lg-4">
        <div class="card bd-rad bg-color app-doughnut-pie col-12 col-lg-4"
          *ngIf="widget.includes('app-doughnut-and-pie-chart'); else noData">
          <div class="card-body">
            <app-doughnut-and-pie-chart></app-doughnut-and-pie-chart>
          </div>
        </div>
        <div class="card bd-rad bg-color flex-fill" *ngIf="widget.includes('app_report_fms'); else noData">
          <div class="card-body">
            <app-widget-report-fms [is_dashboard]="is_dashboard"></app-widget-report-fms>
          </div>
        </div>
      </div>

      <!-- Third Row -->
      <div class="d-flex flex-column flex-lg-row gap-3 gap-lg-4">
        <div class="card bd-rad bg-color bg-weather app-weather col-12 col-lg-4"
          *ngIf="widget.includes('app_meteo'); else noData">
          <div class="card-body">
            <app-widget-weather></app-widget-weather>
          </div>
        </div>
        <div class="d-flex flex-fill gap-3 gap-lg-4">
          <div class="card bd-rad color-sun sun-chart w-100" *ngIf="widget.includes('app-sun-chart'); else noData">
            <div class="card-body bg-sun bd-rad">
              <app-sun-chart></app-sun-chart>
            </div>
          </div>
          <div class="card bd-rad color-water water-chart w-100"
            *ngIf="widget.includes('app-water-chart'); else noData">
            <div class="card-body bg-water bd-rad">
              <app-water-chart></app-water-chart>
            </div>
          </div>
        </div>
      </div>

      <!-- Fourth Row -->
      <div class="gap-3 gap-lg-4">
        <div class="card border-none" *ngIf="widget.includes('app-bar-chart'); else noData">
          <div class="card-body">
            <app-bar-chart></app-bar-chart>
          </div>
        </div>
      </div>

      <!-- Fifth Row -->
      <div>
        <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-latestShipments'); else noData">
          <app-latestShipments [actor]="'producer_id'"></app-latestShipments>
        </div>
      </div>
    </div>

    <!-- Right container (container destra) -->
    <div class="d-flex flex-column col-12 col-lg-4 gap-3 gap-lg-4 px-0">
      <!-- First Widget (widget alert only Desktop) -->
      <div class="d-none d-lg-block">
        <div class="card bd-rad box-alert">
          <div class="card-body" id="card-fixed-height">
            <app-alert></app-alert>
          </div>
        </div>
      </div>

      <!-- Second Widget -->
      <div>
        <div class="card h-100 bd-rad bg-color" *ngIf="widget.includes('app-line-chart'); else noData">
          <div class="card-body">
            <app-line-chart></app-line-chart>
          </div>
        </div>
      </div>

      <!-- Third Widget -->
      <div>
        <div class="card h-100 bd-rad bg-quantity pb-0">
          <div class="card-body">
            <app-batch-waste-used></app-batch-waste-used>
          </div>
        </div>
      </div>

      <!-- Fourth Widget -->
      <div>
        <div class="card h-100 bd-rad bg-quantity pb-0">
          <div class="card-body">
            <app-actor-waste-used-widget></app-actor-waste-used-widget>
          </div>
        </div>
      </div>


      <!-- Fifth Widget -->
      <div>
        <div class="card h-100 bd-rad bg-feedback" *ngIf="widget.includes('feedback'); else noData">
          <div class="card-body">
            <app-widget-feedback></app-widget-feedback>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="card bd-rad bg-no-data h-100">
    <div class="card-body">
      <app-no-data></app-no-data>
    </div>
  </div>
</ng-template>
