<div class="actor-waste-used-page">
  <div class="actor-waste-used-page__header">
    <div class="title">Scarto</div>
    <div [formGroup]="actorForm" class="select-actor">
      <nz-select class="custom-select placeholder-color
      " nzPlaceHolder="Operatore" formControlName="user_id" (ngModelChange)="getWasteInfoByUser()">
        <nz-option *ngFor="let actor of actorOptionsArray" [nzLabel]="actor.name" [nzValue]="actor.id">
        </nz-option>
      </nz-select>
      <nz-select class="custom-select placeholder-color
      " nzPlaceHolder="Mese" formControlName="date" (ngModelChange)="getWasteInfoByUser()">
        <nz-option *ngFor="let month of months" [nzLabel]="capitalizeFirstLetter( month.label)"
          [nzValue]="month.value"></nz-option>
      </nz-select>
      <nz-select class="custom-select placeholder-color
      " nzPlaceHolder="Tipo" formControlName="productType" (ngModelChange)="getWasteInfoByUser()">
        <nz-option *ngFor="let type of productType" [nzLabel]="type.label" [nzValue]="type.value">
        </nz-option>
      </nz-select>
    </div>
  </div>

  <div class="actor-waste-used-page__content">
    <div class="box-chart" *ngIf="showChart">
      <canvas id="lineChart" baseChart [options]="lineChartOptions" [type]="'line'" [data]="lineChartData"
        [legend]="lineChartLegend">
      </canvas>
    </div>

    <div #tooltipTemplate class="chartjs-tooltip"></div>
  </div>
</div>