import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/helpers/utils';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnDestroy {
  title = 'ng2-charts-demo';
  selectedProvince!: string;
  province: any;
  showWidget = false;
  isEmpty!: boolean;
  alert!: string;
  selectedField: { id: null; label: null } = { id: null, label: null };

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Arance',
        fill: false,
        tension: 0.5,
        borderColor: '#EF7F00',
        backgroundColor: 'rgba(255,0,0,0.3)',
        pointRadius: 0,
      },
      {
        data: [],
        label: 'Limoni',
        fill: false,
        tension: 0.5,
        borderColor: '#FAC36A',
        backgroundColor: 'rgba(255,0,0,0.3)',
        pointRadius: 0,
      },
    ],
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
  };
  public lineChartLegend = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService,
    private readonly _loggingService: LoggingService,
    private readonly router: Router,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getMunicipality();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.getMarketPriceWidgetData();
  }

  getMunicipality(): void {
    this._chartService.getMarketMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.province = r.data;
        this.selectedProvince = this.province[0].id;
        this.selectedField = this.province[0];
        this.getData();
      });
  }

  getMarketPriceWidgetData(): void {
    this.showWidget = false;
    this._chartService
      .getMarketPriceDataFiltered(this.selectedProvince)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.isEmpty = r.data.isEmpty;
        if (!this.isEmpty) {
          this.lineChartData.datasets[0].data =
            r.data.widgetData.datasets.data[0];
          this.lineChartData.datasets[1].data =
            r.data.widgetData.datasets.data[1];
          this.lineChartData.labels = r.data.widgetData.labels;
          this.showWidget = true;
        } else {
          this.lineChartData.datasets[0].data = [0];
          this.lineChartData.datasets[1].data = [0];
          this.lineChartData.labels = ['Nessun dato'];
          this.showWidget = true;
        }
      });
  }

  explodeWidget(event?: Event) {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    const { id, label } = this.selectedField;
    const params = {
      tab: 3,
      municipality_id: id,
      municipality_desc: label,
    };
    this._utils.handleWidgetClick('/app/statistics', 4, params);
    this.router.navigate(['app/statistics'], {
      queryParams: params,
    });
  }
}
