import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar.service';
import { ComponentService } from '../component.service';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { toast } from 'src/app/app.component';

@Component({
  selector: 'app-lotHistoryMobile',
  templateUrl: './lotHistoryMobile.component.html',
  styleUrls: ['./lotHistoryMobile.component.scss'],
})
export class LotHistoryMobileComponent implements OnInit, OnDestroy {
  batch!: any;
  mobileIcon!: string;
  mobiletype!: string;

  roles = [
    { role: 'Produttore', id: 2, selected: true },
    { role: 'Selezione e stoccaggio', id: 5, selected: false },
    { role: 'Distributore', id: 6, selected: false },
    { role: 'Punto vendita', id: 4, selected: false },
  ];

  data: any;
  currentRole: any;

  finalisation: any;
  consumerFeedBackForm: FormGroup;
  modalVisible: boolean = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _sidebarService: SidebarService,
    private readonly _componentService: ComponentService,
    private readonly _route: ActivatedRoute,
    private readonly _fb: FormBuilder
  ) {
    this.consumerFeedBackForm = this._fb.group({
      age: new FormControl(null, Validators.required),
      gender: new FormControl(null, Validators.required),
      vendor_id: new FormControl(null, Validators.required),
      finalisation_id: new FormControl(null, Validators.required),
      batch_id: new FormControl(null, Validators.required),
      rating: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      feedback: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this._sidebarService.hideSidebar();
    this.currentRole = 2;
    this._route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.batch = params['batch_id'];
      });
    this.getMyHistoryBatchTimeline();
    this.getFinalisationByBatchUnique();
  }

  ngOnDestroy(): void {
    this._sidebarService.showSidebar();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectProductType(id: number): void {
    this.roles.forEach((p) => (p.selected = false));
    const roleId = this.roles.find((p) => p.id === id);
    if (roleId) {
      roleId.selected = true;
      this.currentRole = roleId.id;
      this.getMyHistoryBatchTimeline();
    }
  }

  getBatches(): void {
    this._componentService.getMyBatchesOnTimeLine()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.data = r.data;
      });
  }

  getMyHistoryBatchTimeline(batch?: any): void {
    if (batch) {
      this.batch = batch;
    }

    this._componentService
      .getMyHistoryBatchTimeline(this.currentRole, this.batch)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.data = [r.data];
        this.mobiletype = r.data.mobile_type;
        this.mobileIcon = r.data.mobile_icon;
      });
  }

  storeConsumerFeedback(): void {
    lastValueFrom(this._componentService.storeConsumerFeedback(this.consumerFeedBackForm.value))
      .then(() => {
        toast.fire({
          title: 'Feedback inviato',
          text: 'Hai lasciato un feedback con successo',
          icon: 'success'
        });
        this.closeModal();
      })
      .catch(() => {
        toast.fire({
          title: 'Errore',
          text: 'Non è stato possibile inviare il feedback. Riprova più tardi.',
          icon: 'warning'
        });
      });
  }

  openModal(): void {
    this.modalVisible = true;
    this.consumerFeedBackForm.patchValue({
      batch_id: this.finalisation.batch_id,
      finalisation_id: this.finalisation.id,
      vendor_id: this.finalisation.user_id,
    });
  }

  closeModal(): void {
    this.modalVisible = false;
    this.consumerFeedBackForm.reset();
  }

  getFinalisationByBatchUnique(): void {
    this._componentService
      .getFinalisationByBatchUnique(this.batch)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.code === 200) {
          this.finalisation = response.data;
        }
      });
  }
}
