import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'drw-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  /**
   * L'array del nome delle colonne da mostrare nella tabella.
   */
  @Input() displayedColumns: string[] = ['id', 'name', 'progress', 'fruit'];

  /**
   * EndPoint della chiamata API per popolare la tabella.
   */
  @Input() endpointDataSource!: string;

  /**
   * Flag booleano che permette di rendere visibile la colonna 'azioni'.
   */
  @Input() actions: boolean = false;

  /**
   * È un oggetto con dei valori booleani che permettono di far visualizzare a schermo i diversi bottoni azioni.
   */
  @Input() actionsButton = { info: true, modify: true, trash: true, approve: true, rejects: true };

  /**
   * Flag booleano che permette di visualizzare il campo di ricerca.
   */
  @Input() isFilter: boolean = false;

  /**
   * Flag booleano che permette di visualizzare la select per filtrare.
   */
  @Input() isFilterFromSelect: boolean = false;

  /**
   * Array di opzioni che popolano la select.
   * electOptions = [
    {
      name: [
              {op: 'produttore', val: 'produttore'},
              {op: 'consumatore', val: 'consumatore'},
            ],
      key: 'role'
    },
    {
      name: [
              {op: 'approvati', val: 1},
              {op: 'respointi', val: 0},
            ],
       key: 'confirmed'
    }
  ]
   */
  @Input() selectOptions: any;

  /**
   * Flag booleano che permette di attivare l'ordinamento della tabella.
   */
  @Input() isSortable: boolean = false;

  /**
   * Array di colonne che possono essere soggette ad ordinamento.
   */
  @Input() sortableColumns: string[] = [];

  /**
   * EndPoint della chiamata API per la stampa in formato PDF.
   */
  @Input() endpointPdf!: string;

  /**
   * EndPoint della chiamata API per l'export in formato EXCEL.
   */
  @Input() endpointExcel!: string;

  /**
   * Variabile che permette di ricaricare le colonne.
   */
  @Input() renderColumns!: BehaviorSubject<string>;

  // /**
  //  * Evento che permette di aggiornare la datatable.
  //  */
  // @Input() refreasData!: BehaviorSubject<any>;

  /**
   * Booleano che permette di cliccare su una row per accedere al dettaglio.
   */
  @Input() clickRow: boolean = false;

  /**
   * Se utilizzare un layout di tabella fisso. L'abilitazione
   * di questa opzione applicherà larghezze di colonna coerenti
   * e ottimizzerà il rendering degli stili permanenti per le tabelle native.
   */
  @Input() fixedLayout: boolean = false;

  /**
   * Evento che passa l'id dell'elemento da eliminare.
   */
  @Output() deletedElement: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Evento che passa l'id di un elemento.
   */
  @Output() element: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento che passa l'id per accedere al dettaglio.
   */
  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output() selectedOption: EventEmitter<any> = new EventEmitter<any>();



  dataSource!: MatTableDataSource<any>;

  // * Paginator
  length!: number;
  pageSize: number = 10;
  pageIndex: number = 0;
  filterSelect: any = null
  pageSizeOptions: number[] = [5, 10, 25];
  showFirstLastButtons: boolean = true;

  body: any = {
    skip: this.pageIndex * this.pageSize,
    take: this.pageSize,
    order: {}
  };

  searchForm!: FormGroup;

  // * Testi eliminazione elemento
  title!: string;
  text!: string;
  cancelButton!: string;
  confirmButton!: string;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private translate: TranslateService,
  ) {
    this.translate.get(['Elimina elemento', 'Sei sicuro di voler eliminare questo elemento?', 'Elimina', 'Annulla']).subscribe(messages => {
      this.title = messages['Elimina elemento'];
      this.text = messages['Sei sicuro di voler eliminare questo elemento?'];
      this.confirmButton = messages['Elimina'];
      this.cancelButton = messages['Annulla'];
    });
  }

  ngOnInit(): void {

    this.searchForm = this.fb.group({ search: "" });
    if (this.actions) this.displayedColumns.push('azioni');
    this.getData();
    this.renderColumns?.subscribe((value: string) => {
      if (value) this.getData();
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.body.skip = this.pageIndex * this.pageSize
    this.body.take = this.pageSize

    this.getData(this.body)
    return event;
  }

  getData(params?: any) {

    if (this.searchForm.value.search) params = { skip: this.pageIndex * this.pageSize, take: this.pageSize, role: this.filterSelect, search: this.searchForm.value.search };

    this.http.post<FormattedResponse<any>>(`${environment.api}/${this.endpointDataSource}`, this.body).subscribe({
      next: (response) => {

        this.dataSource = new MatTableDataSource<any>(response.data);
        this.length = response.total ?? 0;
      }
    });
  }

  deleteElement(id: string) {
    this.deletedElement.emit(id);
    this.getData()
  }

  edit(id: string, actionType: string) {
    const par = {
      id: id,
      actionType: actionType
    }
    this.element.emit(par);
    this.getData()
  }

  selectFilter(selectedOption: any,  key: any) {
    this.selectedOption.emit(selectedOption.value);
    let value;
    if (selectedOption.value != 'null') {
      value = selectedOption.value;
    }else{
      value = null;
    }
    // const value = selectedOption.value;
    this.filterSelect = value
    this.body[key] = value;
    this.getData(this.body)
}

  sortData(sort: Sort) {
    this.body.order[sort.active] = sort.direction;
    this.getData(this.body);
  }

  sortableHeader(headerName?: string): boolean {
    if (this.isSortable && headerName != 'azioni') return true;
    if (this.sortableColumns.findIndex(element => element == headerName) != -1) return true;
    // console.log('SORT COL', headerName);

    return false;
  }

  exportData(type: 'excel' | 'pdf') {
    switch (type) {
      case 'excel':
        this.http.get(`${environment}/${this.endpointExcel}`);
        break;
      case 'pdf':
        this.http.get(`${environment}/${this.endpointPdf}`);
        break;
      default:
        break;
    }
  }

  rowClick(row: any) {
    if (this.clickRow) {
      this.rowClicked.emit(row);

    }
  }

  reloadTableData() {
    this.getData()
  }


}
