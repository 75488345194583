<div class="main-cont widget-report-fms" *ngIf="showWidget">
    <div class="d-flex justify-content-between">
        <div>
            <h4 class="header-widget">Ultime Attività</h4>
        </div>
        <div (click)="explodeWidget()" *ngIf="is_dashboard">
            <img src="/assets/img/arrow.svg" alt="" class="cursor-pointer">
        </div>
    </div>

    <div class="my-3 d-flex align-items-center" style="flex-grow: 1;"
        *ngFor="let report of (reportsFMS | async)?.slice(0, 4); let i=index">
        <ng-container *ngIf="i < 4">
            <div class="d-flex flex-row justify-content-start pe-0 gap-3"
                [ngClass]="{'col-6': !(isMobileWithTablet$ | async), 'col-7': (isMobileWithTablet$ | async)}">
                <img [src]="getCategoryImage(report.product.category)" alt="" />
                <div class="d-flex flex-column justify-content-start px-0 w-100">
                    <div class="fw-500 fs-12">{{report.date | date: 'dd MMM YYYY'}}</div>
                    <div class="fw-500 fs-16">{{report.product.name}}</div>
                    <div class="fw-500 fs-14">{{(report.product.batch?.batch_unique_identifier ?
                        report.product.batch.batch_unique_identifier : 'Nessun lotto')}}</div>
                </div>
            </div>
            <div class="fs-12 d-flex px-0"
                [class.justify-content-start]="is_dashboard && !(isMobileWithTablet$ | async)"
                [class.justify-content-end]="(is_dashboard && (isMobileWithTablet$ | async)) || !is_dashboard"
                [ngClass]="{
                    'col-4': is_dashboard && !(isMobileWithTablet$ | async),
                    'col-5': is_dashboard && (isMobileWithTablet$ | async),
                    'col-6': !is_dashboard
                }">
                <div [class]="getActivityClass(getFirstWord(report.activity))" class="box">
                    {{ getFirstWord(report.activity) }}
                </div>
            </div>
            <div class="col-2 fs-14 text-g justify-content-end" *ngIf="is_dashboard && !(isMobileWithTablet$ | async)">
                {{report.amount}} {{ getUnitOfMeasure(report.activity) }}
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="!showWidget">
    <h1>No data</h1>
</div>