import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import { ComponentService } from '../component.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss'],
})
export class WarehouseComponent implements OnInit, OnDestroy {
  entrance!: any;
  exit!: any;

  selectedYear: any;
  selectedMonth: number;
  selectedDate: string = 'Gennaio 2024';
  loadWidget: boolean = false;
  occupation: any;
  role: any;
  years: any;
  months: any;
  chartLegend: any;
  data: any;

  months2: any[] = [
    { label: 'Gennaio', value: 1 },
    { label: 'Febbraio', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Aprile', value: 4 },
    { label: 'Maggio', value: 5 },
    { label: 'Giugno', value: 6 },
    { label: 'Luglio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Settembre', value: 9 },
    { label: 'Ottobre', value: 10 },
    { label: 'Novembre', value: 11 },
    { label: 'Dicembre', value: 12 },
  ];
  cols: string[] = [
    'Lotto',
    'Produttore',
    'Giacenza',
    'Quantitá',
    'Date di ingresso e uscita',
    'Destinazione',
  ];
  doughnutChartData: ChartData<'doughnut'> = {
    labels: [''],
    datasets: [
      {
        data: [],
        backgroundColor: [''],
        hoverOffset: 4,
      },
    ],
  };
  doughnutChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '70%',
    radius: '60%',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  doughnutChartPlugins: Plugin[] = [{
    id: 'customPlugin',
    beforeDraw: (chart) => {
      const width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      const fontSize = Math.min(height, width) * 0.08;
      ctx.font = fontSize + 'px sans-serif';
      ctx.textBaseline = 'middle';

      const text = this.occupation + '/100%',
        textWidth = ctx.measureText(text).width,
        textX = (width - textWidth) / 2,
        textY = height / 2;

      ctx.fillText(text, textX, textY);

      const subTextFontSize = fontSize * 0.5;
      ctx.font = subTextFontSize + 'px sans-serif';
      ctx.save();
    },
  }];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly _route: ActivatedRoute
  ) {
    const currentDate = moment();
    this.selectedMonth = Number(currentDate.format('MM'));
  }

  ngOnInit(): void {
    this.getLastThreeYears();
    this.role = this._route.snapshot.paramMap.get('role');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getInfoInStock(): void {
    this.loadWidget = false;
    this._componentService
      .getInfoInStock(this.selectedYear, this.selectedMonth)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.doughnutChartData.labels = r.data.chart.labels;
        this.doughnutChartData.datasets[0].data = r.data.chart.datasets[0].data;
        this.doughnutChartData.datasets[0].backgroundColor = r.data.chart.datasets[0].backgroundColor;
        this.occupation = parseFloat(r.data.chart.occupation.toFixed(1));
        this.loadWidget = true;
        this.chartLegend = r.data.percentage;
        this.entrance = r.data.type_total_sum.receiving;
        this.exit = r.data.type_total_sum.requesting;
        this.data = r.data.data?.map((item: any) => {
          if (item.arriving?.indexOf('/') >= 0) {
            item.arriving = moment(item.arriving, 'DD/MM/YYYY').format('DD-MM-YYYY');
          }
          if (item.shipping?.indexOf('-') >= 0) {
            item.shipping = moment(item.shipping, 'DD/MM/YYYY').format('DD-MM-YYYY');
          }
          return item;
        });
      });
  }

  getMonths(): void {
    if (!this.selectedYear) {
      this.selectedYear = this.years[0];
    }
    this._componentService.getMonths(this.selectedYear)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.months = r.data;
        const monthKeys = this.months.map((month: any) => month.key);
        if (!monthKeys.includes(this.selectedMonth)) {
          this.selectedMonth = this.months[this.months.length - 1].key;
        }
        this.getInfoInStock();
      });
  }

  getLastThreeYears(): number[] {
    const currentYear = new Date().getFullYear();
    let date = [currentYear, currentYear - 1, currentYear - 2];
    this.years = date;
    this.getMonths();
    return date;
  }

  stringMonth(month: number): string {
    return this.months2?.find((m) => m.value == month)?.label ?? '';
  }
}
