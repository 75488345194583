<div class="seller-upcomung-products-widget">
  <div class="d-flex justify-content-between">
    <h4 class="header-widget">Ultimi prodotti in arrivo</h4>
    <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
      <img src="/assets/img/arrow.svg" alt="" />
    </span>
  </div>

  <div class="mt-3">
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col font-title text-center text-lg-start" [class.col--only-desktop]="col.isOnlyDesktop"
            *ngFor="let col of cols">
            {{col.label}}
          </th>
          <th class="col font-title text-center text-lg-start"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data">
          <td class="col text-style-big padd text-start align-content-center">{{ item.batch.batch_unique_identifier}}</td>
          <td class="col text-style-medium text-center text-lg-star align-content-center">{{ item.arrival_date | date:'dd-MM-yyyy'}}</td>
          <td class="col col--only-desktop text-style-big align-content-center">{{ item.batch.user.name }}</td>
          <td class="col text-style-big text-center text-lg-start align-content-center">{{ item.batch.variety}}</td>
          <td class="col col--only-desktop text-style-medium align-content-center">{{ item.quantity}} kg</td>
          <td class="col col--only-desktop text-style-big align-content-center">
            <div
              [ngClass]="{'rovinato': item.status == 'Rovinato', 'buono': item.status == 'Buono', 'discreto': item.status == 'Discreto'}">
              {{item.status}}
            </div>
          </td>
          <td class="col align-content-center"><app-badge-blockchain *ngIf="item.is_verified"></app-badge-blockchain></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>