import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})
export class ChatbotComponent {
  userInput: string = '';
  messages: { text: string; type: 'user' | 'bot' }[] = [];

  constructor(private readonly _http: HttpClient) {}

  sendMessage() {
    if (!this.userInput.trim()) return;

    this.messages.push({ text: this.userInput, type: 'user' });
    this._http
      .post<any>('http://127.0.0.1:8000/api/citracebot', {
        input_text: this.userInput,
      })
      .subscribe(
        (response) => {
          this.messages.push({ text: response.result, type: 'bot' });
          this.userInput = '';
          this.userInput = '';
        },
        (error) => {
          this.messages.push({
            text: 'Something went wrong. Please try again.',
            type: 'bot',
          });
        }
      );
  }
}
