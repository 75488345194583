import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { BehaviorSubject, finalize, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';
import { ComponentService } from 'src/app/components/component.service';
import { toast } from 'src/app/app.component';

@Component({
  selector: 'app-shipping-status',
  templateUrl: './shipping-status.component.html',
  styleUrls: ['./shipping-status.component.scss']
})
export class ShippingStatusComponent implements OnInit, OnDestroy {
  formShipment!: FormGroup;
  selectedField!: string;

  percentage: number = 70;
  startAddress: string = 'Viale Africa, 31';
  endAddress: string = 'Via Garibaldi, 514';
  temperatureWithDegree: string = '';
  size: NzButtonSize = 'small';
  placeholder: string = 'Seleziona un mezzo';
  temperatureInput: any;
  product: any;
  isVisibleModal: boolean = false;
  isOkLoading: boolean = false;
  fields: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  citrus_type: any[] = [
    { icon: "/assets/icon/mini-orange.svg", category: 1 },
    { icon: "/assets/icon/mini-lemon.svg", category: 2 }
  ];
  array_select: any[] = [
    { value: "Vagone refrigerato", label: 'Vagone refrigerato' },
    { value: "Furgone", label: 'Furgone' },
    { value: "Moto ape", label: 'Moto ape' },
    { value: "Camion refrigerato", label: 'Camion refrigerato' },
  ];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _services: ComponentService,
    private readonly _commonService: CommonService
  ) {
    this.formShipment = this._fb.group({
      lotto: [null],
      mezzo: [null],
      temperatura: [null],
    });

    this.formShipment.get('temperatura')
      ?.valueChanges
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.temperatureInput = value ? `${value}°` : '';
      });
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openModalAndPatchForm(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.formShipment.patchValue({
      lotto: this.product.batch_id,
      mezzo: this.product.transport,
      temperatura: this.product.temperature
    });
    this.isVisibleModal = true;
  }

  getData(): void {
    this._services.lastDistributor()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.product = res.data;

        this.formShipment.patchValue({
          lotto: res.data.batch_id,
          mezzo: res.data.transport,
          temperatura: res.data.temperature
        });
      });
  }

  openModal(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.isVisibleModal = true;
  }

  handleCancel(): void {
    this.formShipment.reset();
    this.isVisibleModal = false;
  }

  getIconPath(category: number): string {
    const citrus = this.citrus_type.find(type => type.category == category);
    return citrus ? citrus.icon : '';
  }

  handleOk(): void {
    const temperatureValue = this.formShipment.value.temperatura;
    const mezzoSelezionato = this.formShipment.value.mezzo;

    if (!mezzoSelezionato) {
      toast.fire({
        title: 'Mezzo di trasporto mancante',
        text: 'Seleziona un mezzo di trasporto',
        icon: 'error'
      });
      return;
    }

    if (!temperatureValue) {
      toast.fire({
        title: 'Temperatura mancante',
        text: 'Campo temperatura vuoto',
        icon: 'error'
      });
      return;
    }

    if (temperatureValue > 40) {
      toast.fire({
        title: 'Temperatura troppo alta',
        text: 'La temperatura non può essere superiore ai 40°',
        icon: 'error'
      });
      return;
    }

    if (temperatureValue < -40) {
      toast.fire({
        title: 'Temperatura troppo bassa',
        text: 'La temperatura non può essere inferiore ai -40°',
        icon: 'error'
      });
      return;
    }

    this.isOkLoading = true;
    this._services.updateLastShippingDistributor(this.product.batch_id, { transport: this.formShipment.value.mezzo, temperature: this.formShipment.value.temperatura })
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.isOkLoading = false)
      )
      .subscribe(res => {
        toast.fire({
          text: 'Mezzo inserito correttamente',
          icon: 'success'
        });
        this.getData();
        this.isVisibleModal = false;
      });
  }
}
