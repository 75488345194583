import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { CommonService } from 'src/app/components/common.service';
import { ComponentService } from 'src/app/components/component.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-sellerUpcomingProducts',
  templateUrl: './sellerUpcomingProducts.component.html',
  styleUrls: ['./sellerUpcomingProducts.component.scss']
})
export class SellerUpcomingProductsComponent implements OnInit, OnDestroy {
  data!: any;

  cols: { label: string, isOnlyDesktop: boolean }[] = [];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _loggingService: LoggingService,
    private readonly _router: Router,
    private readonly _componentService: ComponentService,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.cols = [
      { label: 'Lotto', isOnlyDesktop: false },
      { label: 'Arrivo', isOnlyDesktop: false },
      { label: 'Produttore', isOnlyDesktop: true },
      { label: 'Prodotto', isOnlyDesktop: false },
      { label: 'Quantitá', isOnlyDesktop: true },
      { label: 'Stato', isOnlyDesktop: true }
    ];
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this._componentService.getArrivingProduct()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        this.data = r?.data;
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('your-products', 1);
    this._router.navigate(['vendor/your-products']);
    const currentUrl = this._router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-doughnut-and-pie-chart'
    }
    lastValueFrom(this._loggingService.logClick(data));
  }
}
