<div class="sale-point-home">
  <div class="row mx-0">
    <!-- Left container (container sinistra) -->
    <div class="d-flex flex-column col-12 col-lg-9 gap-3 gap-lg-4 px-0 mb-3 mb-lg-0 ps-lg-0 pe-lg-4">
      <div class="most-used-activities-container">
        <app-most-used-activities [favoriteActivities]="favoriteActivities">
        </app-most-used-activities>
      </div>
      <div class="d-flex flex-column flex-lg-row gap-3 gap-lg-4">
        <div class="card bg-color bd-rad col-12 col-lg-5" *ngIf="widget.includes('app-SalesStatus'); else noData">
          <div class="bg-sale-status border-mini-widget">
            <div class="bg-img-sale-status widget-min-h">
              <app-SalesStatus></app-SalesStatus>
            </div>
          </div>
        </div>
        <div class="d-flex flex-nowrap gap-3 w-100">
          <div class="card bd-rad bg-color discard-day" *ngIf="widget.includes('app-SalesStatus'); else noData">
            <div class="bg-discard-day border-mini-widget widget-min-h">
              <app-DiscardOfTheDay></app-DiscardOfTheDay>
            </div>
          </div>
          <div class="card bd-rad bg-color best-selling" *ngIf="widget.includes('app-SalesStatus'); else noData">
            <div class="border-mini-widget widget-min-h">
              <app-bestSellingProduct></app-bestSellingProduct>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-lg-none">
        <ng-container *ngTemplateOutlet="widgetAddReport">
        </ng-container>
      </div>
      <div class="d-flex flex-column flex-lg-row gap-3 gap-lg-4">
        <div class="bg-color bd-rad col-12 col-lg-5 products-for-sale" *ngIf="widget.includes('app-ProductsForSale'); else noData">
          <div class="border-mini-widget big-widget-h">
            <app-ProductsForSale></app-ProductsForSale>
          </div>
        </div>
        <div class="d-flex flex-fill gap-3 gap-lg-4">
          <div class="card bd-rad bg-color w-100 sales-times" *ngIf="widget.includes('app-SalesTimes'); else noData">
            <div class="border-mini-widget big-widget-h">
              <app-SalesTimes></app-SalesTimes>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="card bd-rad bg-color seller-upcomung-products" *ngIf="widget.includes('app-sellerUpcomingProducts'); else noData">
          <div class="border-mini-widget">
            <app-sellerUpcomingProducts></app-sellerUpcomingProducts>
          </div>
        </div>
      </div>
      <div>
        <div class="card bd-rad bg-color" *ngIf="widget.includes('app-latestShipments'); else noData">
          <app-latestShipments [actor]="'venditore_id'"></app-latestShipments>
        </div>
      </div>
    </div>

    <!-- Right container (container destra) -->
    <div class="d-flex flex-column col-12 col-lg-3 gap-3 gap-lg-4 px-0">
      <div class="d-none d-lg-block">
        <ng-container *ngTemplateOutlet="widgetAddReport">
        </ng-container>
      </div>
      <div class="card bd-rad bg-color seller-in-stock" *ngIf="widget.includes('app-SellerInStock'); else noData">
        <div class="border-mini-widget big-widget-h">
          <app-SellerInStock></app-SellerInStock>
        </div>
      </div>
      <div class="card bd-rad bg-quantity pb-0">
        <div class="card-body">
          <app-batch-waste-used></app-batch-waste-used>
        </div>
      </div>
      <div class="card bd-rad bg-quantity pb-0">
        <div class="card-body">
          <app-actor-waste-used-widget></app-actor-waste-used-widget>
        </div>
      </div>
      <div class="card bd-rad bg-feedback" *ngIf="widget.includes('feedback'); else noData">
        <div class="card-body">
          <app-widget-feedback></app-widget-feedback>
        </div>
      </div>
    </div>

    <!-- begin: Widget add report -->
    <ng-template #widgetAddReport>
      <div class="card bd-rad bg-color add-report" *ngIf="widget.includes('app-addReport'); else noData">
        <div class="bg-add-day-report border-mini-widget widget-min-h bg-img-add-day-report">
          <app-addReport></app-addReport>
        </div>
      </div>
    </ng-template>
    <!-- end: Widget add report -->
  </div>

  <ng-template #noData>
    <div class="card bd-rad bg-no-data h-100 mb-1">
      <div class="card-body">
        <app-no-data></app-no-data>
      </div>
    </div>
  </ng-template>
</div>