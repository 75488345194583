<div class="best-selling-product">
  <div class="title">
    <h4 class="header-widget">Il più venduto</h4>
    <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
      <img src="/assets/img/arrow.svg" alt="" />
    </span>
  </div>
  <div class="box-info" [class.align-items-center]="!data && !sold">
    <div class="box-info__box-img">
      <img src="../../../../../../assets/img/best-sale.svg" alt="Medaglia vendite">
    </div>
    <div class="d-flex flex-column flex-grow-1 gap-1" *ngIf="data || sold; else noData">
      <div *ngIf="data">
        <div class="box-info__variety" *ngIf="data.variety">
          {{data.variety}}
        </div>
        <div class="box-info__lot" *ngIf="data.batch_unique_identifier">
          {{data.batch_unique_identifier}}
        </div>
      </div>
      <div class="box-info__sold-value" *ngIf="sold">
        {{sold}}<span>kg</span>
      </div>
    </div>
    <ng-template #noData>
      <span>Nessun dato disponibile</span>
    </ng-template>
  </div>
</div>