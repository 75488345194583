import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/helpers/utils';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';

@Component({
  selector: 'app-SellerInStock',
  templateUrl: './SellerInStock.component.html',
  styleUrls: ['./SellerInStock.component.scss'],
})
export class SellerInStockComponent implements OnInit, OnDestroy {
  legendData!: any;
  noData!: string;
  text!: any;

  showWidget: boolean = false;
  doughnutChartData: ChartData<'doughnut'> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ['#EF7F00', '#FAC36A'],
        hoverOffset: 4
      },
    ]
  };
  doughnutChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '70%',
    radius: '80%',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
    }
  };

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService,
    private readonly _loggingService: LoggingService,
    private readonly _router: Router,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getPlantsWidgetData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPlantsWidgetData(): void {
    this._chartService.getWidgetVenditoreInStockData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.text = r.total;
        this.doughnutChartData.datasets[0].data = r.data.widgetData.datasets.data;
        this.doughnutChartData.labels = r.data.widgetData.labels;
        this.doughnutChartData.datasets[0].backgroundColor = r.data.widgetData.datasets.backgroundColor;
        this.legendData = r.data.percentage;
        this.showWidget = true;
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('vendor/your-products', 20);
    this._router.navigate(['vendor/your-products'], {
      queryParams: { tab: 2 }
    });
    const currentUrl = this._router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-doughnut-and-pie-chart',
    };
    lastValueFrom(this._loggingService.logClick(data));
  }
}
