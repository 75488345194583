import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { Subject, takeUntil } from 'rxjs';
import { ComponentService } from 'src/app/components/component.service';

@Component({
  selector: 'app-SalesTimes',
  templateUrl: './SalesTimes.component.html',
  styleUrls: ['./SalesTimes.component.scss']
})
export class SalesTimesComponent implements OnInit, OnDestroy {
  selectType!: any;
  average!: any;

  showWidget: boolean = false;
  types: any[] = [
    { type: 'Arance', value: 1 },
    { type: 'Limoni', value: 2 }
  ];
  barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: 'rgba(236, 160, 37, 1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        borderRadius: 25,
        barThickness: 20
      }
    ]
  };
  barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function (value) {
            return value + ' g';
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };
  barChartType: any = 'bar';
  barChartLegend: boolean = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService
  ) { }

  ngOnInit(): void {
    this.selectType = this.types[0].value;
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.showWidget = false;
    this._componentService.getVenditaTime(this.selectType)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        this.barChartData.labels = r.data.widgetData.labels;
        this.barChartData.datasets[0].backgroundColor = r.data.widgetData.datasets.backgroundColor;
        this.barChartData.datasets[0].borderColor = r.data.widgetData.datasets.borderColor;
        this.barChartData.datasets[0].data = r.data.widgetData.datasets.data[0];
        this.average = r.data.average;
        this.showWidget = true;
      });
  }
}
