import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ChartService } from 'src/app/components/charts/chart.service';

@Component({
  selector: 'app-widget-water-transformer',
  templateUrl: './widget-water-transformer.component.html',
  styleUrls: ['./widget-water-transformer.component.scss'],
})
export class WidgetWaterTransformerComponent implements OnInit, OnDestroy {
  selectedField!: string;

  prov: any;
  waterLiters: any;
  placeHolder: string = 'Seleziona una provincia';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService
  ) { }

  ngOnInit(): void {
    this.getTransformerMunicipality();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.getTransformerWaterData();
  }

  getTransformerMunicipality(): void {
    this._chartService.getTransformerMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        if (r.data.length > 0) {
          this.prov = r.data;
          this.selectedField = r.data[0]?.id;
          this.getTransformerWaterData();
        } else {
          this.placeHolder = 'Nessun dato';
        }
      });
  }

  getTransformerWaterData(): void {
    this._chartService
      .getTransformerWaterData(this.selectedField)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.waterLiters = r.data?.water_liters;
      });
  }
}
