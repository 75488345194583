import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { ChartService } from 'src/app/components/charts/chart.service';
import { CommonService } from 'src/app/components/common.service';
import { Utils } from 'src/app/shared/helpers/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-widget-products-coming-soon',
  templateUrl: './widget-products-coming-soon.component.html',
  styleUrls: ['./widget-products-coming-soon.component.scss'],
})
export class WidgetProductsComingSoonComponent implements OnInit, OnDestroy {
  selectedField!: string;
  showWidget: boolean = false;
  label!: ['Lotto', 'Data', 'Produttore', 'Prodotto', 'Tipologia', 'Quantitá'];

  batchesArray: any;
  role = localStorage.getItem('role') ?? 5;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService,
    private readonly _loggingService: LoggingService,
    private readonly router: Router,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getTransformerproductsComingSoonData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTransformerproductsComingSoonData(): void {
    const noDataPlaceholder = {
      lotto: 'Nessun dato',
      data: 'Nessun dato',
      productor: 'Nessun dato',
      img: '/assets/img/no-data-placeholder.svg',
      type: 'Nessun dato',
      quantity: 0,
    };

    this._chartService
      .getTransformerproductsComingSoonData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        if (r.data.length > 0) {
          this.batchesArray = r.data.map((item: any) => {
            if (item.data) {
              item.data = moment(item.data, 'DD/MM/YYYY').format('DD-MM-YYYY');
            }
            return item;
          });
          this.showWidget = true;
        } else {
          this.batchesArray = [noDataPlaceholder];
          this.showWidget = false;
        }
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('app-widget-products-coming-soon', 12);
    this.router.navigate(['warehouse/' + this.role]);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-products-coming-soon',
    };
    lastValueFrom(this._loggingService.logClick(data));
  }

  dateHandling(date: string | null): string {
    return date ?? 'In transito';
  }
}
