import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IQrCode } from '../modules/venditore/interfaces/IQrCode';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { IBatchOption } from './historical-weather/interfaces/IBatchOption';
import { FormattedResponse } from '../shared/models/formatted-response';
import { IPredictComponentRequest } from './sidebar/Interfaces/IPredictComponentRequest';
import { IClickExplodeWidgetRequest } from './sidebar/Interfaces/IClickExplodeWidgetRequest';
import { CONFIG } from '../shared/helpers/interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class ComponentService {
  private readonly clickSubject = new Subject<void>();
  clickEvent$ = this.clickSubject.asObservable();

  private readonly batchesSubject = new BehaviorSubject<IBatchOption[]>([]);
  batches$ = this.batchesSubject.asObservable();

  private readonly objectSource = new BehaviorSubject<any>(null);
  currentObject = this.objectSource.asObservable();

  constructor(private readonly _http: HttpClient) { }

  triggerClickEvent() {
    this.clickSubject.next();
  }

  changeObject(obj: any) {
    this.objectSource.next(obj);
  }

  getAllProducts(skip: any, take: any) {
    return this._http.get<any>(`${environment.api}/products/${skip}/${take}`);
  }

  getAllFeedback(data: any) {
    return this._http.get<any>(`${environment.api}/feedback`, data);
  }

  getUsersBatch(data: any) {
    return this._http.post<any>(`${environment.api}/batches/users`, data);
  }

  createNewFeedback(data: any) {
    return this._http.post<any>(`${environment.api}/feedback`, data);
  }

  handleRoles() {
    return this._http.get<any>(`${environment.api}/feedback/roles`);
  }

  getBatchByRoleInTimeLine(role: string) {
    const params = new HttpParams().set('role', role);
    return this._http.get<any>(
      `${environment.api}/batches/roles/${role}/unique-identifiers`, {params}
    );
  }

  updateFeedback(data: any) {
    return this._http.post<any>(`${environment.api}/feedback`, data);
  }

  updateLastShippingDistributor(lotto: any, data: any) {
    return this._http.post<any>(
      `${environment.api}/distributor/giacency/batches/${lotto}/shipping/update`,
      data
    );
  }

  getMyBatchesTracking() {
    return this._http.get<any>(`${environment.api}/batches/my-tracking`);
  }

  getMyTimelineBatch() {
    return this._http.get<any>(`${environment.api}/batches/timeline/my`);
  }

  sendBatch(lotto: string) {
    return this._http.get<any>(`${environment.api}/send-batch/${lotto}`);
  }

  distributorMyBatch(batchUId: any) {
    return this._http.get<any>(`${environment.api}/distributor/batches/${batchUId}`);
  }

  lastBatchShipping(actor: string) {
    return this._http.get<any>(`${environment.api}/distributor/giacency/batches/latest/${actor}`);
  }

  lastDistributoreShipping(isArriving: string) {
    return this._http.get<any>(
      `${environment.api}/distributor/giacency/shipping/latest?is_arriving=${isArriving}`
    );
  }

  lastDistributor() {
    return this._http.get<any>(`${environment.api}/distributor/giacency/batches/latest`);
  }

  getYourTransformation(id: any) {
    return this._http.get<any>(`${environment.api}/your-treatments/${id}`);
  }

  getLastProductVendit() {
    return this._http.get<any>(`${environment.api}/sales/last`);
  }

  getVenditaTime(type: any) {
    return this._http.get<any>(`${environment.api}/giacency/point-vendit/sale-time/${type}`);
  }

  getVenditeState() {
    return this._http.get<any>(`${environment.api}/giacency/point-vendit/sale-state`);
  }

  scartoDelGiorno() {
    return this._http.get<any>(`${environment.api}/giacency/point-vendit/day-waste`);
  }

  getMostSold() {
    return this._http.get<any>(`${environment.api}/giacency/point-vendit/most-sold`);
  }

  getArrivingProduct() {
    return this._http.get<any>(`${environment.api}/giacency/point-vendit/arriving-product`);
  }

  getMyBatchOnGiacencyPoint(type: any) {
    return this._http.get<any>(
      `${environment.api}/giacency/point-vendit/batches/my?type=${type}`
    );
  }

  storeDailyReport(data: any) {
    return this._http.post<any>(`${environment.api}/giacency/point-vendit/daily-report/store`, data);
  }

  getMyBatchesOnTimeLine(type?: any, search?: any, status?: string) {

    if (type && search && status) {
      return this._http.get<any>(`${environment.api}/batches/history/my?type=${type}&search=${search}&status=${status}`);
    }
    if (search && status) {
      return this._http.get<any>(`${environment.api}/batches/history/my?type=${type}&search=${search}&status=${status}`);
    }
    if (type && status) {
      return this._http.get<any>(`${environment.api}/batches/history/my?type=${type}&status=${status}`);
    }
    if (status) {
      return this._http.get<any>(`${environment.api}/batches/history/my?status=${status}`);
    }
    if (search) {
      return this._http.get<any>(`${environment.api}/batches/history/my?type=${type}&search=${search}`);
    } else {
      return this._http.get<any>(`${environment.api}/batches/history/my?type=${type}`);
    }
  }

  getProductsInStore(category?: number | null, search?: string | null, skip?: number, take?: number) {
    let apiUrl: string = `${environment.api}/vendors/products/in-store?category=${category}&search=${search}`;

    if (skip !== null && skip !== undefined && take !== null && take !== undefined) {
      apiUrl += `&skip=${skip}&take=${take}`;
    }

    return this._http.get<any>(apiUrl);
  }

  getProductsToArrive(category?: number | null, search?: string | null, skip?: number, take?: number) {
    let apiUrl: string = `${environment.api}/vendors/products/arriving?category=${category}&search=${search}`;

    if (skip !== null && skip !== undefined && take !== null && take !== undefined) {
      apiUrl += `&skip=${skip}&take=${take}`;
    }

    return this._http.get<any>(apiUrl);
  }

  qrCodeGenerate(data: IQrCode) {
    return this._http.post<any>(`${environment.api}/qr-codes/generate`, data);
  }

  getUserRole() {
    return this._http.get<any>(`${environment.api}/users/role`);
  }

  getMyHistoryBatchTimeline(roleId: any, batch: any) {
    return this._http.get<any>(
      `${environment.api}/batches/history/${batch}/timeline/${roleId}`
    );
  }

  getMyBatches(municipality_id?: number | null): Observable<IBatchOption[]> {
    return this._http
      .get<IBatchOption[]>(
        `${environment.api}/producer/batches?municipality_id=${municipality_id}`
      )
      .pipe(tap((batches) => this.batchesSubject.next(batches)));
  }

  getMyPlante() {
    return this._http.get<any>(`${environment.api}/production-trend/my-plants`);
  }

  getMunicipality() {
    return this._http.get<any>(`${environment.api}/municipalities/my`);
  }

  trendProduction(year: any, type: any, plante_id: any) {
    return this._http.get<any>(
      `${environment.api}/production-trend?year=${year}&type=${type}&plante_id=${plante_id}`
    );
  }

  getWeather(params: any) {
    return this._http.post<any>(`${environment.api}/weather/current`, params);
  }

  venditorCordinate(params: any) {

    return this._http.post<any>(`${environment.api}/qr-codes/vendor/coordinates`, params);
  }

  getQrProductsByCategory(category: any, skip: any, take: any) {
    return this._http.get<any>(`${environment.api}/qr-codes/products?category=${category}&skip=${skip}&take=${take}`);
  }

  getBatchFieldSowingAndReaping(batch_id: number) {
    return this._http.get<any>(
      `${environment.api}/producers/batches/field?batch_id=${batch_id}}`
    );
  }

  getMarketPricesForStatistics(municipality_id: number | null, year: string, category: number | null) {
    return this._http.get<any>(
      `${environment.api}/market-prices/${municipality_id}/${year}/${category}/producer`
    );
  }

  getMarketPricesForStatisticsByMonth(municipality_id?: number | null, month?: number, year?: string, category?: number | null) {
    return this._http.get<any>(
      `${environment.api}/market-prices/${municipality_id}/${month}/${year}/${category}/producer/month`
    );
  }

  getYourTreatments(date: any, search: string | null = null) {
    return this._http.get<any>(
      `${environment.api}/trasformations/your-treatments?date=${date}&search=${search}`
    );
  }

  addNotesToTreatment(treatmentId: number, params: any): Observable<FormattedResponse<any>> {
    return this._http.put<FormattedResponse<any>>(`${environment.api}/treatments/trasformations/${treatmentId}`, params);
  }


  getFile(path: string, filename: string): Observable<Blob> {
    return this._http.get(`${environment.api}/files/${filename}?path=${path}`, { responseType: 'blob' });
  }

  getReadyProducts(type: any) {
    return this._http.get<any>(`${environment.api}/batches/timeline/ready-products/${type}`);
  }

  transferData(data: any) {
    return this._http.post<any>(`${environment.api}/batches/timeline/store`, data);
  }

  getMonths(year?: any) {
    return this._http.get<any>(`${environment.api}/get-months/${year}`);
  }

  getInfoInStock(year?: any, month?: any) {
    if (year && month) {
      return this._http.get<any>(`${environment.api}/stock-info?year=${year}&month=${month}`);
    }
    if (year) {
      return this._http.get<any>(`${environment.api}/stock-info?year=${year}`);
    }
    if (month) {
      return this._http.get<any>(`${environment.api}/stock-info?month=${month}`);
    }
    else {
      return this._http.get<any>(`${environment.api}/stock-info`);
    }
  }

  confermBatch(batchId: any) {
    return this._http.patch<any>(`${environment.api}/batches/timeline/update`, batchId);
  }

  getUserInfo() {
    return this._http.get<any>(`${environment.api}/profile`);
  }

  updateUserInfo(data: any) {
    return this._http.put<any>(`${environment.api}/profile`, data);
  }

  getUserInfoTransformer() {
    return this._http.get<any>(`${environment.api}/profile/transformer-profile`);
  }

  postUserInfoTransformer(data: any) {
    return this._http.post<any>(`${environment.api}/profile/transformer-profile`, data);
  }

  updateUserInfoTransformer(data: any, id: any) {
    return this._http.put<any>(`${environment.api}/profile/transformer-profile/${id}`, data);
  }

  getUserInfoDistributor() {
    return this._http.get<any>(`${environment.api}/profile/distributor-profile`);
  }

  postUserInfoDistributor(data: any) {
    return this._http.post<any>(`${environment.api}/profile/distributor-profile`, data);
  }

  updateUserInfoDistributor(data: any, id: any) {
    return this._http.put<any>(`${environment.api}/profile/distributor-profile/${id}`, data);
  }

  getUserInfoVendor() {
    return this._http.get<any>(`${environment.api}/profile/vendor-profile`);
  }

  postUserInfoVendor(data: any) {
    return this._http.post<any>(`${environment.api}/profile/vendor-profile`, data);
  }

  updateUserInfoVendor(data: any, id: any) {
    return this._http.put<any>(`${environment.api}/profile/vendor-profile/${id}`, data);
  }

  getAllProducerPlants() {
    return this._http.get<any>(`${environment.api}/profile/producer/plants`);
  }

  getSinglePlantInfo(plante_id: any) {
    return this._http.get<any>(`${environment.api}/profile/producer/plant/${plante_id}`);
  }

  postProducerPlant(data: any) {
    return this._http.post<any>(`${environment.api}/profile/producer/plante`, data);
  }

  updateProducerPlant(data: any, plantId: any) {
    return this._http.put<any>(`${environment.api}/profile/producer/plante/${plantId}`, data);
  }

  deletePlant(plant: any) {
    return this._http.delete<any>(`${environment.api}/profile/producer/plant/${plant}`);
  }

  postInteractionEnginePredictComponent(data: IPredictComponentRequest) {
    return this._http.post<any>(`${environment.api}/interaction-engine/predict-component`, data);
  }

  postInteractionEnginePredictAction(device_type: any) {
    return this._http.post<any>(`${environment.api}/interaction-engine/predict-action`, device_type);
  }

  postClickExplodeWidget(data: IClickExplodeWidgetRequest) {
    return this._http.post<any>(`${environment.api}/interaction-engine/click-explode-widget`, data);
  }

  getBatchesByPlante(plante_id: number) {
    return this._http.get<any>(`${environment.api}/get-batch-by-plante/${plante_id}`);
  }

  getTreatmentsByBatch(batch_id: number) {
    return this._http.get<any>(`${environment.api}/get-activity-by-batch/${batch_id}`);
  }

  getProductByPlanteId(plante_id: number) {
    return this._http.get<any>(`${environment.api}/producer-market-price/plantes/${plante_id}/products`);
  }

  getActivitiesByProductId() {
    return this._http.get<any>(`${environment.api}/producer-market-price/products/activities`);
  }

  postActivityPrice(data: any) {
    return this._http.post<any>(`${environment.api}/producer-market-price/activities/store`, data);
  }

  updateActivityPrice(data: any, market_price_id: any) {
    return this._http.put<any>(`${environment.api}/producer-market-price/activities/${market_price_id}/update`, data);
  }

  getActivitiesForProducer(skip: number, take: number) {
    return this._http.get<any>(`${environment.api}/producer-market-price/activities?skip=${skip}&take=${take}`);
  }

  getActivitiesSumPrices(batchUId: string) {
    return this._http.get<any>(`${environment.api}/batches/${batchUId}/activities-sum`);
  }

  getAllBatchesByUser() {
    return this._http.get<any>(`${environment.api}/other-market-price/batches/user`);
  }

  getAllProductByBatchId(batchId: number) {
    return this._http.get<any>(`${environment.api}/get-all-products-by-batch/${batchId}`);
  }

  getAllActivitiesNonProducer() {
    return this._http.get<any>(`${environment.api}/other-market-price/activities`);
  }

  getActivitiesForNonProducer(skip: number, take: number) {
    return this._http.get<any>(`${environment.api}/other-market-price/batches/activities?skip=${skip}&take=${take}`);
  }

  storeConsumerFeedback(data: any) {
    return this._http.post<any>(
      `${environment.api}/consumer/feedback/add`,
      data,
      {
        context: new HttpContext().set(CONFIG, { isPublic: true }),
      }
    );
  }

  getFinalisationByBatchUnique(batch_unique_identifier: string) {
    return this._http.get<any>(`${environment.api}/consumer/feedback/finalisation?batch_unique_identifier=${batch_unique_identifier}`);
  }

  getVendorFinalisationsInStore() {
    return this._http.get<any>(`${environment.api}/vendor/feedbacks/products`);
  }

  getFinalisationConsumerFeedbacks(data: any) {
    const params = new HttpParams()
      .set('finalisation_id', data.finalisation_id)
      .set('age', data.age)
      .set('gender', data.gender)
    return this._http.get<any>(`${environment.api}/vendor/feedbacks/consumer`, { params });
  }

  getBatchWeight(batchUId: string) {
    return this._http.get<any>(`${environment.api}/batches/${batchUId}/weight`);
  }

  getBatchesWeightOnTimeline(batchUId: string) {
    return this._http.get<any>(`${environment.api}/batches/timeline/${batchUId}/info`);
  }

  getUserBatchesTimeline() {
    return this._http.get<any>(`${environment.api}/batches/timeline/auth-user`);
  }

  getWasteInfoByActor() {
    return this._http.get<any>(`${environment.api}/waste-infos`);
  }

  getWasteAverage() {
    return this._http.get<any>(`${environment.api}/batches/timeline/waste-avg`);
  }

  getLinkedUsers() {
    return this._http.get<any>(`${environment.api}/users/linked`);
  }

}

