import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { BehaviorSubject, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { ChartService } from 'src/app/components/charts/chart.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-widget-latest-transformations',
  templateUrl: './widget-latest-transformations.component.html',
  styleUrls: ['./widget-latest-transformations.component.scss']
})
export class WidgetLatestTransformationsComponent implements OnInit, OnDestroy {
  sowWidget!: boolean
  moData!: string;
  selectedField!: string;
  prov!: any;
  list!: any;

  size: NzButtonSize = 'small';
  lastTransform: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService,
    private readonly _loggingService: LoggingService,
    private readonly _router: Router,
    private readonly _utils: Utils
  ) { }

  ngOnInit(): void {
    this.getLatestTransformationData()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getActivityClass(value: number): string {
    switch (value) {
      case 1:
        return 'Spremitura';
      case 2:
        return 'Selezione';
      case 3:
        return 'Confettura';

      default:
        return '';
    }
  }

  getCategoryImage(value: any): string | null {
    switch (value) {
      case '1':
        return '/assets/img/agrumi/orange-icon.svg';
      case '2':
        return '/assets/img/agrumi/lemon-icon.svg';
      default:
        return null;
    }
  }

  getTransformerMunicipality() {
    this._chartService.getTransformerMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        this.prov = r.data;
        this.selectedField = r.data[0].id
      });
  }

  formattaData(data: Date): string {
    const nomiMesi = ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'];

    const giorno = data.getDate();
    const mese = nomiMesi[data.getMonth()];
    const anno = data.getFullYear();

    return `${giorno} ${mese} ${anno}`;
  }

  getLatestTransformationData(): void {
    this._chartService.getLatestTransformationData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        if (r.data.length > 0) {
          r.data.forEach((element: any) => {
            let dataString = element.data;
            let dateParts = dataString.split('/');

            let primaData = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
            let secondaData = new Date(`${dateParts[5]}-${dateParts[4]}-${dateParts[3]}`);

            let primaDataFormattata = this.formattaData(primaData);
            let secondaDataFormattata = this.formattaData(secondaData);

            let dataFormattata = `${primaDataFormattata} / ${secondaDataFormattata}`;

            element.data = dataFormattata;
          });

          this.list = r.data;
          this.sowWidget = true;
        } else {
          this.list = [
            {
              img: "Nessun dato",
              data: "Nessun dato",
              producer: "Nessun dato",
              type: "Nessun dato",
              quantity: 0,
              lotto: "Nessun dato"
            }
          ]
          this.sowWidget = true;
          this.moData = 'No data';
        }
      });
  }

  explodeWidget(): void {
    this._utils.handleWidgetClick(null, 10);

    const currentUrl = this._router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-latest-transformations'
    }
    lastValueFrom(this._loggingService.logClick(data));
  }
}
