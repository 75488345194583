<div class="prodotti-container">
  <div class="d-flex justify-content-between">
    <div>
      <h4 class="header-widget">Prodotti in arrivo</h4>
    </div>
    <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
      <img src="/assets/img/arrow.svg" alt="Vai ai dettagli" />
    </span>
  </div>

  <div *ngIf="showWidget && batchesArray.length > 0" class="list-container">
    <div class="row list-header">
      <div class="col-4 col-lg-2 text-lg-start">Lotto</div>
      <div class="col-4 col-lg-2 text-lg-start">Arriva il:</div>
      <div class="col-2 d-none d-lg-block">Produttore</div>
      <div class="col-4 col-lg-2 text-lg-start">Prodotto</div>
      <div class="col-2 d-none d-lg-block">Tipologia</div>
      <div class="col-2 d-none d-lg-block">Quantitá</div>
    </div>

    <div class="list-item" *ngFor="let item of batchesArray; let i = index">
      <div class="row cont" *ngIf="i < 5">
        <div class="col-4 col-lg-2 data-values text-lg-start">
          {{ item.lotto }}
        </div>
        <div class="col-4 col-lg-2 data-values text-lg-start">
          {{ item?.data }}
        </div>
        <div class="col-2 data-values d-none d-lg-flex flex-wrap">
          {{ item.productor }}
        </div>
        <div class="col-4 col-lg-2 img text-lg-start">
          <img [src]="item.img" alt="Immagine prodotto" />
        </div>
        <div class="col-2 data-values d-none d-lg-flex flex-wrap">
          {{ item.type }}
        </div>
        <div class="col-2 data-values d-none d-lg-block">
          {{ item.quantity }} kg
        </div>
      </div>
    </div>
  </div>

  <!-- da verificare l'aspetto del no data template e il posizionamento del testo -->
  <div *ngIf="!showWidget" class="no-data-container">
    <img src="assets/icon/no_data.svg" alt="Nessun dato disponibile" class="no-data-img" />
    <p class="no-data-message">
      Nessun prodotto in arrivo al momento. <br />
      Controlla più tardi o aggiorna i dati.
    </p>
  </div>
</div>
