import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { ChartService } from '../charts/chart.service';
import { IFilterOption } from '../historical-weather/interfaces/IFilterOption';
import { IFilterOptionYourProducts } from 'src/app/modules/venditore/interfaces/IFilterOptionYourProducts';
import { ComponentService } from '../component.service';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { Observable, Subject, isObservable, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMarketPrice } from './IMarketPrices';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-statistics-market-prices',
  templateUrl: './statistics-market-prices.component.html',
  styleUrls: ['./statistics-market-prices.component.scss'],
})
export class StatisticsMarketPricesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() municipalityId: number | null = null;
  @Input() municipalityLabel: string | null = null;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | null = null;

  filtersForm: FormGroup;
  title = 'ng2-charts-demo';
  municipalities: any;
  showWidget = false;
  isEmpty!: boolean;
  alert!: string;
  selectedYear: string;
  selectedMonth: number;
  selectedMonthLabel: string | null = null;
  showWIdget: boolean = false;
  marketData: IMarketPrice[] = [];
  fillMarketData: boolean = false;
  isToggleCollapsedForMobileFiltering: boolean = true;

  columns = [
    'Prodotto',
    'Prezzo medio mensile',
    'Prezzo massimo mensile',
    'Prezzo minimo mensile',
  ];
  translationMap: { [key: string]: string } = {
    Orange: 'Arance',
    Lemon: 'Limoni',
  };
  months = [
    { label: 'Gennaio', value: 1 },
    { label: 'Febbraio', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Aprile', value: 4 },
    { label: 'Maggio', value: 5 },
    { label: 'Giugno', value: 6 },
    { label: 'Luglio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Settembre', value: 9 },
    { label: 'Ottobre', value: 10 },
    { label: 'Novembre', value: 11 },
    { label: 'Dicembre', value: 12 },
  ];

  years = Array.from({ length: 3 }, (_, i) => ({
    label: `${new Date().getFullYear() + i}`,
    value: new Date().getFullYear() + i,
  }));

  lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Arance',
        fill: false,
        tension: 0.5,
        borderColor: '#EF7F00',
        backgroundColor: 'rgba(255,0,0,0.3)',
        pointRadius: 0,
      },
      {
        data: [],
        label: 'Limoni',
        fill: false,
        tension: 0.5,
        borderColor: '#FAC36A',
        backgroundColor: 'rgba(255,0,0,0.3)',
        pointRadius: 0,
      },
    ],
  };
  filterOptions: IFilterOptionYourProducts[] = [
    {
      label: 'Tutti',
      value: null,
      image: null,
      active: true,
    },
    {
      label: 'Arance',
      value: 1,
      image: 'orange-icon',
      active: false,
    },
    {
      label: 'Limoni',
      value: 2,
      image: 'lemon-icon',
      active: false,
    },
  ];
  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x'
  };

  selectedCategory: number | null = null;
  marketPriceLegend = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly _chartService: ChartService,
    private readonly _componentService: ComponentService,
    private readonly _commonService: CommonService
  ) {
    this.getMunicipality();

    this.filtersForm = this.fb.group({
      municipality_id: [null, Validators.required],
      month: [new Date().getMonth() + 1],
      year: [new Date().getFullYear()],
      category: null,
    });

    const currentDate = moment();
    this.selectedMonth = Number(currentDate.format('MM'));
    this.selectedYear = currentDate.format('YYYY');
  }

  ngOnInit(): void {
    this.filtersForm.valueChanges.subscribe(() => {
      const { municipality_id, month, year, category } = this.filtersForm.value;
      if (month && year) {
        this.updateStatistics(municipality_id, month, year, category);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['municipalityId'] &&
      !changes['municipalityId'].isFirstChange()
    ) {
      this.updateStatistics(
        this.municipalityId!,
        this.selectedMonth,
        this.selectedYear,
        this.selectedCategory
      );
    }
  }

  getMunicipality(): void {
    this._chartService.getMarketMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.municipalities = r.data;
        this._componentService.getMunicipality().subscribe((response) => {
          this.handleMunicipalityResponse(response);
          this.updateFormAndStatistics();
        });
      });
  }

  filterNonZeroData(data: number[]): any[] {
    return data.filter((value) => value !== 0);
  }

  generateYearOptions(range: number): IFilterOption[] {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: range }, (_, i) => ({
      label: (currentYear + i).toString(),
      value: currentYear + i,
    }));
  }

  generateMonthOptions = (range: number): IFilterOption[] => {
    return Array.from({ length: range }, (_, i) => ({
      label: this.months.map((m) => m.label)[i % 12],
      value: i % 12,
    }));
  };

  isObservable(obj: any): obj is Observable<any> {
    return isObservable(obj);
  }

  translateProductName(name: string): string {
    return this.translationMap[name] || name;
  }

  updateStatistics(
    municipality_id: number,
    month: number,
    year: string,
    category: number | null
  ): void {
    this.getMarketPriceStatistics(municipality_id, year, category);
    this.getMarketPricesForStatisticsByMonth(
      municipality_id,
      month,
      year,
      category
    );
  }

  getMarketPriceStatistics(
    municipality_id: number,
    year: string,
    category: number | null = null
  ): void {
    this.showWidget = false;
    this._componentService
      .getMarketPricesForStatistics(municipality_id, year, category)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.handleMarketPriceStatisticsResponse(r.data);
      });
  }

  handleMarketPriceStatisticsResponse(data: any): void {
    this.isEmpty = data.isEmpty;
    if (!this.isEmpty) {
      this.lineChartData.datasets[0].data = this.filterNonZeroData(
        data.widgetData.datasets.data[0] || []
      );
      this.lineChartData.datasets[1].data = this.filterNonZeroData(
        data.widgetData.datasets.data[1] || []
      );
      this.lineChartData.labels = data.widgetData.labels;
      this.showWidget = true;
      this.selectedMonthLabel = this.months.find((m) => m.value === this.selectedMonth)?.label ?? '';
      this.chart?.update();
    } else {
      this.resetChart();
    }
  }

  resetChart(): void {
    this.lineChartData.datasets.forEach((dataset) => (dataset.data = [0]));
    this.lineChartData.labels = ['Nessun dato'];
    this.showWidget = true;
  }

  selectFilter(filter: IFilterOptionYourProducts, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    if (!filter.active) {
      this.filterOptions.forEach((f) => (f.active = false));
      filter.active = true;
      this.filtersForm.patchValue({ category: filter.value });
    }
  }

  getMarketPricesForStatisticsByMonth(
    municipality_id: number,
    month: number,
    year: string,
    category: number | null
  ): void {
    this._componentService
      .getMarketPricesForStatisticsByMonth(
        municipality_id,
        month,
        year,
        category
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.marketData = response.data || [];
        this.fillMarketData = true;
      });
  }

  toggleCollapsedForMobileFiltering(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.isToggleCollapsedForMobileFiltering = !this.isToggleCollapsedForMobileFiltering;
  }

  private handleMunicipalityResponse(response: any): void {
    if (response) {
      const match = this.findMatchingMunicipality(response);
      this.municipalityId = this.municipalityId ?? match?.id;
      this.municipalityLabel = this.municipalityLabel ?? match?.label;
    } else {
      this.setDefaultMunicipality();
    }
  }

  private findMatchingMunicipality(response: any): any {
    return this.municipalities.find((mun: any) =>
      response.data.some((res: any) => res.label === mun.label)
    );
  }

  private setDefaultMunicipality(): void {
    this.municipalityId = this.municipalityId ?? this.municipalities[0].id;
    this.municipalityLabel =
      this.municipalityLabel ?? this.municipalities[0].label;
  }

  private updateFormAndStatistics(): void {
    if (this.municipalityId) {
      this.filtersForm.get('municipality_id')?.patchValue(this.municipalityId);
    }

    const { municipality_id, month, year, category } = this.filtersForm.value;
    this.updateStatistics(municipality_id, month, year, category);
  }
}
