import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private readonly _http: HttpClient) { }

  confirmUser(user_id: number) {
    const dataToRemove = {};
    return this._http.patch<any>(`${environment.api}/users/${user_id}/confirm`, dataToRemove);
  }

  disable(user_id: number) {
    const dataToRemove = {};
    return this._http.patch<any>(`${environment.api}/users/${user_id}/disable`, dataToRemove);
  }

  deleteUser(id: number) {
    return this._http.delete<any>(`${environment.api}/users/${id}`);
  }

  getAllRoles() {
    return this._http.get<any>(`${environment.api}/roles`);
  }

  getAllwidgets(data: any) {
    return this._http.post<any>(`${environment.api}/widgets`, data);
  }

  getAllwidgetSelectRole(roleId: number) {
    return this._http.get<any>(`${environment.api}/widgets/admin/roles?role=${roleId}`);
  }

  getAllwidgetRole(role_id: any) {
    return this._http.get<any>(`${environment.api}/widgets/roles/${role_id}`);
  }

  attachRole(data: any) {
    return this._http.post<any>(`${environment.api}/widgets/roles/attach`, data);
  }

  detachRole(data: any, id: any) {
    return this._http.post<any>(`${environment.api}/detach-role-widget/${id}`, data);
  }
}
