import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';
import { ComponentService } from 'src/app/components/component.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-distributor-lots',
  templateUrl: './distributor-lots.component.html',
  styleUrls: ['./distributor-lots.component.scss'],
})
export class DistributorLotsComponent implements OnInit, OnDestroy {
  @Input() actor!: number;

  selectedField!: string;
  status_card: any;
  placeholder: string = 'Seleziona lotto';
  fields: any;
  form: FormGroup;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _productorService: ComponentService,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) {
    this.form = this.fb.group({
      lotto: '',
    });
  }

  ngOnInit(): void {
    this.getMyBatches();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMyBatches(): void {
    this._productorService.getMyBatchesTracking()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.fields = r.data;
        this.form.patchValue({ lotto: this.fields[0] });
        this.selectedField = this.fields[0];
        this.getMyBatch();
      });
  }

  getMyBatch(): void {
    switch (this.actor) {
      case 6:
        this.form.value.lotto = this.selectedField;
        this._productorService
          .distributorMyBatch(this.form.value.lotto)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: { data: any }) => {
            this.status_card = res.data.steps;
            console.log('this.status_card', this.status_card);
          });
        break;
      case 5:
        this.form.value.lotto = this.selectedField;
        this._productorService
          .getYourTransformation(this.form.value.lotto)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: { data: any }) => {
            this.status_card = res.data.steps;
            console.log('this.status_card', this.status_card);
          });
        break;
      default:
        break;
    }
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    const params = {
      batch_unique_identifier : this.selectedField
    };
    this._utils.handleWidgetClick('/app/your-treatments', 32, params);
    this.router.navigate(['app/your-treatments']);
  }
}
