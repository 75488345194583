import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentService } from '../component.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-lotHistory',
  templateUrl: './lotHistory.component.html',
  styleUrls: ['./lotHistory.component.scss'],
})
export class LotHistoryComponent implements OnInit, OnDestroy {
  status!: string;

  statusArray: any = [];
  alert = 0;
  userRole: any;
  modalNoteVisible: boolean = false;
  modalLotVisible: boolean = false;
  selectedItemIndex: number = 0;
  selectedItem!: any;
  lotForm: FormGroup;
  selectedBatch: any;
  userId: any;
  roleId: string | null = localStorage.getItem('role_id');
  productsType = [
    {
      id: 0,
      label: 'Tutti',
      selected: true,
    },
    {
      id: 1,
      path: '../../../assets/icon/modal-orange-icon.svg',
      label: 'Arance',
      selected: false,
    },
    {
      id: 2,
      path: '../../../assets/icon/modal-lemon-icon.svg',
      label: 'Limoni',
      selected: false,
    },
  ];
  cols: any;
  data: any;
  type: any;
  search: any;
  active: boolean = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly router: Router,
    private readonly _fb: FormBuilder,
    private readonly _auth: AuthService,
    private readonly _commonService: CommonService
  ) {
    this.lotForm = this._fb.group({
      waste: [null],
    });
  }

  ngOnInit(): void {
    this.getUser();
    this.getUserRole();
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUserRole(): void {
    this._componentService
      .getUserRole()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.userRole = r;
        if (this.userRole == 2) {
          this.cols = [
            'Lotto',
            'Prodotto',
            'Produttore',
            'Quantitá',
            'Quantità scartata',
            'Stato',
            'Note',
          ];
        } else {
          this.cols = [
            'Lotto',
            'Prodotto',
            'Produttore',
            'Quantitá',
            'Quantità scartata',
            'Stato',
            'Note',
            '',
          ];
        }
      });
  }

  getData(type?: any, search?: any, status?: string): void {
    this.statusArray = [];
    this._componentService
      .getMyBatchesOnTimeLine(type, search, status)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        if (r.data !== null && r.data.length > 0) {
          this.data = r.data;
          this.data.forEach((element: any) => {
            if (
              element.is_waiting == 1 &&
              (element.confirmed == 0 || element.confirmed == null)
            ) {
              this.statusArray.push(element);
            }
          });
          this.alert = this.statusArray.length;
          this.active = true;
        } else {
          this.data = [
            {
              batch_id: 'Nessun dato',
            },
          ];
          this.active = false;
        }
      });
  }

  selectProductType(id: number, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.productsType.forEach((p) => (p.selected = false));
    const product = this.productsType.find((p) => p.id === id);
    if (product) {
      product.selected = true;
      this.type = product.id;
      let src;
      if (this.search) {
        src = this.search;
      } else {
        src = '';
      }
      if (this.status) {
        this.getData(this.type, src, this.status);
      } else {
        this.getData(this.type, src);
      }
    }
  }

  searchData(): void {
    if (this.status) {
      this.getData(this.type, this.search, this.status);
    } else {
      this.getData(this.type, this.search);
    }
  }

  goTo(id: any, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event) || !this.active) {
      return;
    }
    this.router.navigate(['/lot-history', id]);
  }

  getFilterDataStatus(): void {
    if (this.search) {
      this.getData(this.type, this.search, this.status);
    } else {
      this.getData(this.type, null, this.status);
    }
  }

  confirm(): void {
    let data = {
      batch_id: this.selectedBatch.batch_id,
      waste: this.lotForm.get('waste')?.value,
    };
    this._componentService
      .confermBatch(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getData();
        this.closeModal();
        Swal.fire({
          title: 'Spedizione confermata',
          text: 'Spedizione confermata',
          html: '<img src="assets/icon/success-icon.svg" alt="Custom Icon">',
          showConfirmButton: false,
          timer: 2000,
        });
      });
  }

  closeNoteModal(): void {
    this.modalNoteVisible = false;
    this.selectedItem = null;
  }

  openNoteModal(selectedItem: any, index: any, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event, true)) {
      return;
    }
    this.modalNoteVisible = true;
    setTimeout(() => {
      this.selectedItemIndex = index;
      this.selectedItem = selectedItem;
    });
  }

  openModal(batch: any, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event, true)) {
      return;
    }
    this.modalLotVisible = true;
    this.selectedBatch = batch;
  }

  closeModal(): void {
    this.modalLotVisible = false;
    this.selectedBatch = null;
    this.lotForm.reset();
  }

  onWasteInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const maxValue = this.selectedBatch.quantity;

    let inputValue = Number(inputElement.value);

    if (inputValue > maxValue) {
      inputElement.value = maxValue.toString();
      this.lotForm.get('waste')?.setValue(maxValue);
    }
  }

  getUser(): void {
    this._auth
      .getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.userId = r.data.id;
      });
  }

  weightManipulationQuantity(row: any): string {
    const isReceiver = row?.receiving_user_id === this.userId;
    const isSameUser = row?.receiving_user_id === row?.requesting_user_id;
    const isSender = row?.requesting_user_id === this.userId;

    const quantity =
      (!isReceiver || isSameUser) &&
      row?.original_quantity !== null &&
      !isSender
        ? row?.original_quantity
        : row?.quantity;

    return quantity ? `${quantity} kg` : 'Nessun prodotto';
  }

  weightManipulationWaste(row: any): string {
    let waste =
      (row?.original_quantity && row?.is_waiting == 0) ||
      (this.roleId === '4' &&
        row.receiving_user_id === row.requesting_user_id &&
        row.original_quantity !== row.quantity)
        ? row?.original_quantity - row?.quantity
        : null;

    const isProducer = this.roleId === '2';

    return waste == null || isProducer ? 'Nessuno scarto' : `${waste} kg`;
  }

  noteManipulation(row: any): string {
    return (this.roleId === '2' ||
      this.roleId === '4' ||
      row.original_note === null) &&
      row?.note !== null
      ? row?.note ?? ''
      : row?.original_note ?? '';
  }
}
