<div class="sales-times-widget" *ngIf="showWidget">
  <div class="d-flex justify-content-between">
    <div>
      <h4 class="header-widget">Tempi di vendita</h4>
    </div>
    <div class="select-type">
      <nz-select nzPlaceHolder="Seleziona tipo" [(ngModel)]="selectType" (ngModelChange)="getData()">
        <nz-option [nzLabel]="type.type" [nzValue]="type.value" *ngFor="let type of types">
          {{type}}
        </nz-option>
      </nz-select>
    </div>
  </div>

  <div class="mit-time">
    Tempo medio: <span>{{average ?? '0'}} giorni</span>
  </div>

  <div class="bar-chart">
    <canvas id="barChart" baseChart [data]="barChartData" [options]="barChartOptions"
      [type]="barChartType" [legend]="barChartLegend">
    </canvas>
  </div>
</div>