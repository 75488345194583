import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { ComponentService } from 'src/app/components/component.service';
import { SidebarService } from 'src/app/components/sidebar.service';

@Component({
  selector: 'app-consumerProducts',
  templateUrl: './consumerProducts.component.html',
  styleUrls: ['./consumerProducts.component.scss'],
})
export class ConsumerProductsComponent implements OnInit, OnDestroy {
  receivedData!: any;
  qrList!: any;
  
  skip: number = 0;
  take: number = 9;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _sidebarService: SidebarService,
    private readonly _componentService: ComponentService,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    this._sidebarService.hideSidebar();
    this.receivedData = history.state.data;
    this.getQr();
  }

  ngOnDestroy(): void {
    this._sidebarService.showSidebar();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getQr(): void {
    this._componentService
      .getQrProductsByCategory(this.receivedData.type, this.skip, this.take)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.qrList = r.data;
        this.qrList.forEach((item: any) => {
          const fileName = item.qr_code_path.split('/').pop();
          this.loadFile(fileName, item);
        });
      });
  }

  loadFile(filename: string, item: any): void {
    const path = 'qrcodes/';
    if (!this.isValidPath(path) || !this.isValidFilename(filename)) {
      console.error('Path o file non validi');
      return;
    }

    this._componentService
      .getFile(path, filename)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((error) => {
          console.error('Errore nel recupero file:', error);
          return of(null);
        })
      )
      .subscribe((blob) => {
        if (!blob || !this.isValidBlob(blob)) {
          console.error('Blob non valido o nullo');
          return;
        }

        const url = URL.createObjectURL(blob);
        item.image = url;
      });
  }

  isValidPath(path: string): boolean {
    const validPathPattern = /^[a-zA-Z0-9/_-]+$/;
    return validPathPattern.test(path);
  }

  isValidFilename(filename: string): boolean {
    const validExtensions = ['png', 'jpg', 'jpeg', 'gif', '.svg'];
    const extension = filename.split('.').pop()?.toLowerCase();
    return extension ? validExtensions.includes(extension) : false;
  }

  isValidBlob(blob: Blob): boolean {
    return blob.type.startsWith('image/');
  }

  goBack(): void {
    this._router.navigate(['/app/dashboard']);
  }
}
