import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IWidget } from 'src/app/components/sidebar/Interfaces/IWidget';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-transporter-home',
  templateUrl: './transporter-home.component.html',
  styleUrls: ['./transporter-home.component.scss'],
})
export class TransporterHomeComponent implements OnInit, OnDestroy {
  @Input() widgetsFromPredict: [] = [];
  @Input() favoriteActivities: IWidget[] | null = null;

  widget = new Array();

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _authService: AuthService,
    private readonly _adminService: AdminService
  ) { }

  ngOnInit(): void {
    this._authService.getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        let role_id = localStorage.getItem('role_id');
        this.getAllWidget(role_id);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAllWidget(role_id: any): void {
    this._adminService.getAllwidgetRole(role_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        r.data.forEach((element: { value: any }) => {
          this.widget.push(element.value);
        });
      });
  }
}
