import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-container2',
  templateUrl: './container2.component.html',
  styleUrls: ['./container2.component.scss']
})
export class Container2Component implements OnInit, OnDestroy {
  widget = new Array;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _authService: AuthService,
    private readonly _adminService: AdminService,
    private readonly _cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._authService.getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        let role_id = localStorage.getItem('role_id');
        this.getAllWidget(role_id);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAllWidget(role_id: any): void {
    this._adminService.getAllwidgetRole(role_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        r.data.forEach((element: { value: any; }) => {
          this.widget.push(element.value);
        });
        this._cdr.detectChanges();
      });
  }
}
