import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';
import { ComponentService } from 'src/app/components/component.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-bestSellingProduct',
  templateUrl: './bestSellingProduct.component.html',
  styleUrls: ['./bestSellingProduct.component.scss']
})
export class BestSellingProductComponent implements OnInit {
  data: any;
  show: boolean = false;
  sold: any;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly _router: Router,
    private readonly _utils: Utils,
    private readonly _loggingService: LoggingService,
    private readonly _commonService: CommonService
  ) {
    this._componentService.clickEvent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getData();
      });
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this._componentService.getMostSold()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        this.data = r.data.batch;
        this.sold = r.data.sold
        this.show = true

        if (!this.data || !this.sold) {
          this.show = false
        }
      });
  }

  explodeWidget(event?: Event) {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('/feedback', 3);
    this._router.navigate(['/feedback']);
    const currentUrl = this._router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-best-selling-product',
    };
    lastValueFrom(this._loggingService.logClick(data));
  }
}
