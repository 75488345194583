<div class="shipping-status">
  <h4 class="header-widget">Spedizione in corso</h4>
  <div class="fs-12 mt-3" *ngIf="product?.batch_id">
    Lotto: <span class="lotto ms-2 px-3 py-1">{{product.batch_id}}</span>
  </div>

  <!-- Barra d'avanzamento -->
  <div class="row mt-5 mx-0">
    <div class="col-2 d-flex align-items-center justify-content-center box-perc">
      <span class="fs-12 me-1">{{ product?.percentage ?? '0' }}%</span>
    </div>

    <div class="col-2 pe-0">
      <div class="progress progress-vertical">
        <div class="progress">
          <span class="progress-bar bg-success" [style.height.%]="product?.percentage ?? 0"
            aria-label="Progress"></span>
        </div>
      </div>
    </div>

    <div class="col-8 d-flex flex-column justify-content-between  ps-0">
      <div class="address-box">
        <div class="fw-500 fs-14">
          Partenza
        </div>
        {{ product?.partenza }}<br />
        <span *ngIf="product?.departure_time">Ore {{ product.departure_time }}</span>
      </div>
      <div class="address-box">
        <div class="fw-500 fs-14">
          Destinazione
        </div>
        {{ product?.destinazione }}<br />
        <span *ngIf="product?.arrived_time">Ore {{ product.arrived_time }}</span>
      </div>
    </div>
  </div>

  <!-- Box trasporto -->
  <div *ngIf="product?.transport!=null; else add">
    <div class="mt-5 d-flex justify-content-between fs-14">
      <div class="fw-500">Modalità di trasporto</div>
      <div class="d-flex flex-row cursor-pointer gap-1" style="color: #056564;" (click)="openModalAndPatchForm($event)"
        (keydown)="openModalAndPatchForm($event)">
        Modifica <img src="/assets/icon/right-arrow.svg" alt="" />
      </div>
    </div>

    <div class="bd-rad mt-2">
      <div class="pt-2 ps-2">
        <span class="camion px-2 py-1">{{product?.transport}}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center mx-3">
        <div>
          <img src="/assets/img/camion.svg" alt="" />
        </div>
        <div>
          <div class="fw-500 fs-14">Temperatura</div>
          <div class="fw-500" [ngClass]="{'fs-1': product?.temperature, 'fs-4': !product?.temperature}">
            <span *ngIf="product?.temperature">{{product.temperature}}&nbsp;C&deg;</span>
            <span *ngIf="!product?.temperature">Nessun dato</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #add>
    <div class="mt-4">
      <span class="mod-subtitle fw-500 fs-14">Mezzo di trasporto</span>
    </div>

    <div class="bd-rad mt-2 add-box">
      <div class="d-flex flex-row cursor-pointer align-items-center" (click)="openModal($event)"
        (keydown)="openModal($event)">
        <div><img src="/assets/img/add-button.svg"></div>
        <div class="ms-2 fw-500">Aggiungi</div>
      </div>
    </div>
  </ng-template>
</div>

<!-- * Modale aggiunta-->
<nz-modal [nzClosable]="false" [nzClassName]="'modal-transport'" [nzTitle]="'Imposta le modalità di trasporto'"
  [nzOkLoading]="isOkLoading" [nzOkText]="'Salva'" [(nzVisible)]="isVisibleModal" (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <form [formGroup]="formShipment" class="mb-5">
      <div class="d-flex flex-rox">
        <div class="me-3 d-flex align-items-center">
          <img [src]="getIconPath(product?.category)" alt="" />
        </div>
        <div class="d-flex flex-column" *ngIf="product?.batch_id">
          <div class="mod-subtitle">Lotto</div>
          <div class="mod-text">{{product.batch_id}}</div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-4">
        <div class="w-50 me-2">
          <div class="mod-subtitle">Mezzo di trasporto</div>
          <select class="form-select" formControlName="mezzo" aria-label="Default select example">
            <option *ngFor="let item of array_select" [value]="item.value">{{ item.label }}
            </option>
          </select>
        </div>
        <div class="w-50 ms-2">
          <div class="mod-subtitle">Temperatura&nbsp;C&deg;</div>
          <input class="form-control" type="number" formControlName="temperatura" placeholder="Inserisci temperatura"
            aria-label="Temperature input" min="-40" max="40">
        </div>
      </div>
    </form>
  </ng-container>
</nz-modal>
