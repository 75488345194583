import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FeedbackService } from 'src/app/shared/services/feedback.service';

@Component({
  selector: 'app-widget-feedback',
  templateUrl: './widget-feedback.component.html',
  styleUrls: ['./widget-feedback.component.scss'],
})
export class WidgetFeedbackComponent implements OnInit, OnDestroy {
  noData!: boolean;
  elements = [
    {
      label: 'Selezione e Stoccaggio',
      image: '/assets/icon/stoccaggio.svg',
      stars: '/assets/img/stelle/tre.svg',
    },
    {
      label: 'Distributore',
      image: '/assets/icon/distribuzione.svg',
      stars: '/assets/img/stelle/cinque.svg',
    },
    {
      label: 'Punto vendita',
      image: '/assets/icon/vendita.svg',
      stars: '/assets/img/stelle/quattro.svg',
    },
    {
      label: 'Consumatore',
      image: '/assets/icon/consumatore.svg',
      stars: '/assets/img/stelle/cinque.svg',
    },
  ];
  imagePaths: any = {
    'Selezione e stoccaggio': '/assets/icon/stoccaggio.svg',
    Distributore: '/assets/icon/distribuzione.svg',
    Produttore: '/assets/icon/prod.svg',
    Venditore: '/assets/icon/vendita.svg',
    Consumatore: '/assets/icon/consumatore.svg',
  };
  feedback: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly feedbackService: FeedbackService,
    private readonly auth: AuthService,
    private readonly _loggingService: LoggingService,
    private readonly router: Router,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getFeedbackUser();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getFeedbackUser(): void {
    this.feedbackService
      .getUserFeedback({ receiving_user_id: this.auth.currentUser.value.id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        console.log('Feedback', res.data);
        if (res.data.length > 0) {
          this.feedback.next(res.data);
          this.noData = false;
        } else {
          this.feedback.next([
            {
              label: 'Nessun dato',
            },
          ]);
          this.noData = true;
        }
      });
  }

  getImagePath(label: string): string {
    return this.imagePaths[label];
  }

  toCamelCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('/app/feedback', 9);
    this.router.navigate(['app/feedback']);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-feedback',
    };
    lastValueFrom(this._loggingService.logClick(data))
      .then((r: any) => {
        this.router.navigate(['app/feedback']);
      });
  }
}
