<div class="batch-waste-used">
  <div class="consumed-container-header">
    <h4 class="header-widget">Scarto lotto</h4>
    <form class="select-lot" [formGroup]="batchForm">
      <nz-select nzPlaceHolder="lotto" formControlName="batch_id" (ngModelChange)="getBatchesWeightOnTimeline()">
        <nz-option *ngFor="let batch of batchOptions" [nzLabel]="batch" [nzValue]="batch">
        </nz-option>
      </nz-select>
    </form>
  </div>

  <div class="content">
    <div class="row justify-content-end align-items-center mx-0">
      <div
        class="content-info col-8 col-lg-9 px-0 d-flex align-items-center justify-content-between justify-content-lg-around">
        <div class="d-flex flex-column justify-content-center">
          <div class="fs-12">Quantità ricevuta</div>
          <span class="weight-value">
            {{ item?.total_quantity ?? '0' }}<span>kg</span>
          </span>
        </div>
        <div class="d-flex flex-column justify-content-center data-cont">
          <div class="fs-12">Quantità scartata</div>
          <span class="weight-value">
            {{ item?.waste ?? '0' }}<span>kg</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>