<div class="widget-arriving-departing-products">
  <div class="d-flex justify-content-between header">
    <div>
      <h4 class="header-widget">Prodotti in arrivo / partenza</h4>
    </div>
    <div class="d-flex justify-content-between">
      <div class="select-year">
        <nz-select nzPlaceHolder="Seleziona un anno" [(ngModel)]="selectedValue" (ngModelChange)="getData()">
          <nz-option nzLabel="In arrivo" [nzValue]="'true'"></nz-option>
          <nz-option nzLabel="In partenza" [nzValue]="'false'"></nz-option>
        </nz-select>
      </div>
      <span class="arrow cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
        <img src="/assets/img/arrow.svg" alt="" />
      </span>
    </div>
  </div>

  <table class="product-table table custom-table" *ngIf="loaded">
    <thead>
      <tr>
        <th class="col padding-th text-lg-start" [class.col--only-desktop]="col.isOnlyDesktop"
          *ngFor="let col of cols">
          {{col.label}}
        </th>
        <th class="col padding-th text-lg-start"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let prod of data">
        <td class="item-1 text-lg-start">{{prod.batchId}}</td>
        <td class="item-2 text-lg-start">
          {{prod.arriving == 'Arrivo' ? (prod.deliveryDate | date:'dd-MM-yyyy') : (prod.shippingDate |
          date:'dd-MM-yyyy')}}
        </td>
        <td class="item-2 item-2--only-desktop">{{prod.agency}}</td>
        <td class="item-1 text-lg-start">{{prod.product}}</td>
        <td class="item-2 item-2--only-desktop">{{prod.timeInSelectionMagazine}} gg</td>
        <td class="item-2 item-2--only-desktop">{{prod.quantity}} kg </td>
        <td class="item-1 text-lg-start"><app-badge-blockchain *ngIf="prod.is_verified"></app-badge-blockchain></td>
      </tr>
    </tbody>
  </table>
</div>
