<div class="widget-in-stock">
  <div class="magazzino-container align-items-center" *ngIf="showWidget">
    <div class="d-flex justify-content-between">
      <h4 class="fw-500 mb-0 header-widget">In Magazzino</h4>

      <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
        <img src="/assets/img/arrow.svg" alt="" />
      </span>
    </div>
    <div *ngIf="doughnutChartData.datasets[0].data">
      <div class="box-chart">
        <canvas id="doughnutChart" baseChart [data]="doughnutChartData" [type]="'doughnut'"
          [options]="doughnutChartOptions" [plugins]="doughnutChartPlugins">
        </canvas>
      </div>

      <div class="legenda row" style="margin-top: 0.1rem">
        <div class="col-12 d-flex" *ngFor="let item of legendData">
          <div class="col-6 d-flex align-items-center">
            <div class="color-spot" [style.background-color]="item.color"></div>
            <div class="col-6"><strong>{{item.name}}</strong></div>
          </div>
          <div class="col-6 d-flex justify-content-between align-items-center">
            <span><strong>({{item.percentage}}%)</strong></span>
            <span><strong>{{item.total}}</strong> ha</span>
          </div>
        </div>
      </div>
    </div>

    <div class="list-container">
      <table class="table">
        <thead class="list-header">
          <tr>
            <th class="font-title text-center ps-0">Lotto</th>
            <th class="font-title text-center">Giacenza</th>
            <th class="font-title text-center">Quantità</th>
          </tr>
        </thead>
        <tbody>
          <tr class="list-item" *ngFor="let item of list">
            <td class="lotto font-lotto text-center ps-0">
              <div class="img d-inline-block">
                <img [src]="item.img" alt="" />
              </div>
              <div class="d-inline-block custom-font-size">
                {{ item.lotto ?? 0}}
              </div>
            </td>
            <td class="days font-lotto text-center">{{ item.days }} giorni</td>
            <td class="kg font-lotto text-center">{{ item.kg }} kg</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="nodata-container align-items-center" *ngIf="!showWidget">
    <div class="d-flex justify-content-between">
      <h4 class="fw-500 mb-0">In Magazzino</h4>

      <img src="/assets/img/arrow.svg" alt="" />
    </div>
    <h1>{{noData}}</h1>
  </div>
</div>