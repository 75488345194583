<div class="sales-status">
  <div class="title">
    <h4 class="header-widget">Stato delle vendite</h4>
  </div>
  <div class="box-info">
    <div class="d-flex flex-column">
      <div class="box-info__title">Oggi</div>
      <div class="box-info__value">
        {{data?.oggi ?? '0'}}<span>kg</span>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="box-info__title">Mese</div>
      <div class="box-info__value">
        {{data?.mese ?? '0'}}<span>kg</span>
      </div>
    </div>
  </div>
</div>