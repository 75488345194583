<nz-layout class="sidebar">
  <nav class="navbar sidebar__mobile">
    <div class="sidebar-mobile-header">
      <a class="sidebar-mobile-header__logo" href="javascript:void(0)" [routerLink]="'./app/dashboard'">
        <img src="assets/icon/logo-citrace-color-1.svg" alt="CiTrace" />
      </a>
      <div class="sidebar-mobile-header__avatar-cta">
        <nz-avatar class="text-uppercase mx-2" [ngStyle]="{ 'background-color': '#00c0bc', 'color': '#056563' }"
          [nzText]="currentUser?.name?.charAt(0) ?? 'U'" (click)="goToProfile($event)" (keydown)="goToProfile($event)">
        </nz-avatar>
        <a class="menu-cta" href="javascript:void(0)" (click)="toggleCollapsedForMobileMenu($event)"
          (keydown)="toggleCollapsedForMobileMenu($event)">
          <i class="icon-custom"
            [ngClass]="{'menu': isToggleCollapsedForMobileMenu, 'arrow-left-orange': !isToggleCollapsedForMobileMenu}"></i>
        </a>
      </div>
    </div>
    <div class="sidebar-mobile-menu" [class.sidebar-mobile-menu--open]="!isToggleCollapsedForMobileMenu">
      <ng-container [ngTemplateOutlet]="menuSections"></ng-container>
    </div>
  </nav>
  <nz-sider nzTheme="light">
    <!-- * Intestazione sidebar -->
    <div class="sidebar-logo d-flex justify-content-center mt-4">
      <a class="logo" href="javascript:void(0)" [routerLink]="'./app/dashboard'">
        <img src="assets/icon/logo-citrace-color-1.svg" alt="CiTrace" />
      </a>
    </div>

    <!-- * Profilo -->
    <div class="sidebar-profile d-flex justify-content-center flex-column align-items-center profile">
      <nz-avatar class="text-uppercase fs-1 mx-2 d-flex align-items-center"
        [ngStyle]="{ 'background-color': '#00c0bc', 'color': '#056563' }"
        [nzText]="currentUser?.name?.charAt(0) ?? 'U'"></nz-avatar>
      <span class="mt-2 fw-medium fs-12" *ngIf="role_id == '2'">Produttore</span>
      <span class="mt-2 fw-medium fs-12" *ngIf="role_id == '3'">Consumatore</span>
      <span class="mt-2 fw-medium fs-12" *ngIf="role_id == '1'">Admin</span>
      <span class="mt-1 fw-semibold">{{ currentUser?.name || 'Utente' }}</span>
      <span (click)="goToProfile($event)" (keydown)="goToProfile($event)">
        <a class="mt-1 mb-5 a-go-profile" *ngIf="role_id != '1'">Vai al profilo</a>
      </span>
    </div>

    <ng-container [ngTemplateOutlet]="menuSections"></ng-container>
  </nz-sider>
</nz-layout>

<ng-template #menuSections>
  <div class="box-menu">
    <ng-container *ngIf="role_id == '1'; else users" class="">
      <ul nz-menu nzMode="inline" nzTheme="light">
        <li class="mt-0" nz-menu-item [class.ant-menu-item-selected]="isPathActive(section.link)"
          (click)="goToPath(section.link, $event)" (keydown)="goToPath(section.link, $event)"
          *ngFor="let section of admin_sections">
          <img class="sidebar-img-admin-section" [src]="section.icon" alt="icona">
          <span>{{ section.label }}</span>
        </li>
      </ul>
    </ng-container>

    <ng-template #users>
      <div class="scrollable-menu">
        <ul nz-menu nzMode="inline" nzTheme="light">
          <ng-container *ngFor="let section of sections">
            <li class="mt-0" nz-menu-item [class.ant-menu-item-selected]="isPathActive(section.link)"
              (click)="goToPath(section.link, $event)" (keydown)="goToPath(section.link, $event)"
              *ngIf="section.role_id?.includes(role_id)">
              <img *ngIf="section.link != 'trasferimento'"
                [class]="isPathActive(section.link) ? 'active-icon' : 'inactive-icon'" [src]="section.icon" alt="icon">
              <img *ngIf="section.link === 'trasferimento'" [src]="section.icon" alt="icon-trasferimento">
              <span class="padding-half" [ngClass]="{'trasferimento-dati-span': section.link === 'trasferimento'}">
                {{ section.label }}
              </span>
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-template>

    <div class="scrollable-menu-footer">
      <ul nz-menu nzMode="inline" nzTheme="light">
        <ng-container *ngFor="let section of sections2">
          <li class="mt-0" nz-menu-item>
            <img class="sidebar-img-section" [src]="section.icon" alt="icona">
            <span class="logout-span" *ngIf="section.callback" (click)="section.callback()" onkeypress="">{{
              section.label }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</ng-template>

<!-- MODALE -->
<nz-modal [nzClosable]="false" [nzClassName]="'modal-transfer-data'" [nzTitle]="'Trasferisci dati'"
  [nzOkLoading]="isOkLoading" [nzOkText]="'Invia'" [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <div class="d-flex flex-column">
      <form [formGroup]="dataForm">
        <div class="select-prod padding-b">
          <div class="modal-text marg-b">Seleziona il tipo di agrume</div>
          <div class="d-flex align-items-center">
            <div class="icon-to-select" *ngFor="let prod of productsType"
              [ngClass]="{'selected': prod.selected, 'border-selected': prod.selected, 'border-to-select': !prod.selected,}"
              (mouseup)="selectProductType(prod.id)">
              <img [src]="prod.path" alt="frutto">
            </div>
          </div>
        </div>

        <div class="select-lotto padding-b">
          <div class="row">
            <div class="col-12 col-lg-6 modal-select">
              <div class="modal-text marg-b">Seleziona il lotto da trasferire</div>
              <nz-select nzAllowClear nzPlaceHolder="Seleziona un lotto" formControlName="lotto"
                [(ngModel)]="selectedbatch" (ngModelChange)="onBatchChange($event)">
                <nz-option *ngFor="let item of batchToSelect" [nzLabel]="item" [nzValue]="item"></nz-option>
              </nz-select>
            </div>
            <div class="col-12 col-lg-6 mt-3 mt-lg-0" *ngIf="selectedbatch && batchWeight">
              <div class="modal-text marg-b">
                <span>Peso del lotto</span>
              </div>
              <div class="mt-0 mt-lg-3 modal-text marg-b">
                <span>
                  {{ batchWeight }} kg
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="padding-b d-flex flex-column">
          <div class="modal-text marg-b">
            Seleziona l’azienda a cui vuoi trasferire le informazioni inerenti al tuo lotto
          </div>
          <div class="row">
            <div class="col-12 modal-select">
              <div class="role-receiver">{{recevingUser}}</div>
              <nz-select class="w-100" nzAllowClear nzPlaceHolder="Seleziona un destinatario" [(ngModel)]="selectedRole"
                formControlName="company">
                <nz-option *ngFor="let item of roleToSelect" [nzLabel]="item.roleName" [nzValue]="item.id"></nz-option>
              </nz-select>
            </div>
          </div>
        </div>

        <div class="padding-b d-flex flex-column">
          <div class="row">
            <div class="col-12 col-lg-6">
              <label class="modal-text marg-b" for="price">Costo trattamenti</label>
              <input id="price" class="modal-input" type="text" nz-input placeholder="es. € 0,20 al kg"
                formControlName="price" currencyMask
                [options]="{ prefix: '€ ', thousands: '.', decimal: ',', precision: 2 }" />
            </div>
          </div>
        </div>

        <div class="padding-b d-flex flex-column">
          <label class="modal-text marg-b" for="note">Note</label>
          <textarea name="note" class="modal-input" nz-input type="text" formControlName="note"
            placeholder="Aggiungi una nota">
            </textarea>
        </div>
      </form>
    </div>

  </ng-container>
</nz-modal>