<div class="container-mobile">
  <div class="title d-flex align-items-center mb-4">
    <div>
      <img [src]="mobileIcon" alt="">
    </div>
    <div class="name">{{mobiletype}}</div>
  </div>
  <div class="mb-4">
    <button nz-button (click)="openModal()">
      Lascia feedback
    </button>
  </div>
  <div class="roles d-flex align-items-center">
    <div class="role" *ngFor="let role of roles" (mouseenter)="selectProductType(role.id)"
      [ngClass]="{'selected-role' : role.selected}">
      {{role.role}}
    </div>
  </div>

  <div class="storico-container">
    <div *ngFor="let item of data">
      <div class="row">
        <div *ngIf="item.dateActions.length > 0" class="grid-container d-flex flex-column">
          <div *ngFor="let day of item.dateActions; let i = index">
            <span class="date">
              {{day.date}}
            </span>

            <div class="row" *ngIf="i === 0">
              <div class="col-1"></div>
              <div class="col-2 border-left d-flex flex-column align-items-center justify-content-center"></div>
              <div class="col-3"></div>
            </div>

            <div class="row" *ngFor="let action of day.actions">
              <div class="col-1"></div>
              <div class="col-1 border-left d-flex flex-column align-items-center justify-content-center">
                <div class="line-circle-container d-flex align-items-center">
                  <div class="circle-line"></div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="col-9 row-test">
                <div class="step-container">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class=" action-title margin-steps" [ngStyle]="{'background-color': action.actionColor}">
                      {{action.action}}
                    </div>
                  </div>

                  <div *ngIf="action.selectionCriteria" class="criterials-container">
                    <div class="selectionCriteria">Criteri di selezione</div>
                    <ul class="action-content margin-steps" *ngFor="let criterials of action.selectionCriteria">
                      <li>
                        <span class="label">{{criterials.label}}: </span>
                        <span class="value">{{criterials.value}}</span>
                      </li>
                    </ul>
                  </div>

                  <div class="action-content margin-steps" *ngFor="let step of action.steps">
                    <div *ngIf="step.label !=='is_verified'">
                      <span class="label">{{step.label}}: </span>
                      <span class="value">{{step.value}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 notes-container ">
                  <div *ngIf="action.notes">
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center">
                        <div>
                          <img src="../../../assets/icon/note-icone.svg" alt="">
                        </div>
                        <div class="note">Note</div>
                      </div>
                      <div class="note-text">{{action.notes}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" grid-row">
          </div>
        </div>
        <div *ngIf="item.dateActions.length == 0">
          <h4>Nessun Dato Presente</h4>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-container">
  <nz-modal [nzClosable]="false" [nzTitle]="'Lascia il tuo feedback'" [(nzVisible)]="modalVisible"
    (nzOnCancel)="closeModal()" (nzOnOk)="storeConsumerFeedback()">
    <ng-container *nzModalContent>
      <form class="consumerFeedBackForm" nz-form [nzLayout]="'inline'" [formGroup]="consumerFeedBackForm">
        <div class="row pb-3">
          <div class="col-6 d-flex align-items-center">
            <span class="me-2">Età:</span>
            <input type="number" formControlName="age" nz-input placeholder="es. 28" class="form-control" />
          </div>
          <div class="col-6 d-flex align-items-center">
            <span class="me-2">Genere:</span>
            <input type="text" formControlName="gender" nz-input placeholder="es. F" class="form-control" />
          </div>
        </div>
        <div class="col-12 pb-3 d-flex flex-column">
          <span class="rate-title mb-1 ">Dai una tua valutazione:</span>
          <nz-rate formControlName="rating"></nz-rate>
        </div>
        <div class="col-12 pb-3 d-flex flex-column">
          <span class="rate-title mb-1">Dai un titolo al tuo feedback:</span>
          <input nz-input type="text" formControlName="title" placeholder="es. Ottima ma ..." class="form-control" />
        </div>
        <div class="col-12 pb-3 d-flex flex-column">
          <span class="rate-title mb-1">Aggiungi il tuo feedback:</span>
          <textarea formControlName="feedback" nz-input rows="2" placeholder="es. Il sapore è gradevole ma... "
            class="form-control"></textarea>
        </div>
      </form>
    </ng-container>
  </nz-modal>
</div>
