import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { ComponentService } from 'src/app/components/component.service';

@Component({
  selector: 'app-feedback-consumer',
  templateUrl: './feedback-consumer.component.html',
  styleUrls: ['./feedback-consumer.component.scss'],
})
export class FeedbackConsumerComponent implements OnInit, OnDestroy {
  @Input() consumerFeedbackList: any[] = [];
  @Input() productsList: any[] = [];

  feedbackForm: FormGroup;

  selectedProduct: {
    id: number;
    product_name: string;
    transformation_name: string;
    producer: string;
  } | null = null;
  ageOptions: number[] = Array.from({ length: 91 }, (_, i) => i + 9);
  startAgeOptions: number[] = this.ageOptions;
  endAgeOptions: number[] = this.ageOptions;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly _fb: FormBuilder
  ) {
    this.feedbackForm = this._fb.group({
      finalisation_id: new FormControl(null, Validators.required),
      age: new FormControl(''),
      startAge: new FormControl(null),
      endAge: new FormControl(null),
      gender: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.getVendorProducts();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getIcon(transformation_name: string): string {
    const iconBasePath = 'assets/img/';
    const iconMap: { [key: string]: string } = {
      fruits: 'frutta-consumer.svg',
      snacks: 'snack1-consumer.svg',
      sweets: 'dolci-consumer.svg',
      soft_drinks: 'bevamde-consumer.svg',
      confitures: 'marmellate-consumer.svg',
      sauces: 'condimenti-consumer.svg',
    };
    return (
      iconBasePath + (iconMap[transformation_name] || 'frutta-consumer.svg')
    );
  }

  selectCard(product: any): void {
    this.selectedProduct = product;
    this.getFeedbackOnProduct();
  }

  isSelected(product: any): boolean {
    return this.selectedProduct === product;
  }

  getVendorProducts(): void {
    this._componentService
      .getVendorFinalisationsInStore()
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((error) => {
          console.error('Error fetching vendor products:', error);
          return of({ code: 500, data: [] });
        })
      )
      .subscribe((response) => {
        this.productsList = response?.data ?? [];
        this.selectedProduct = this.productsList?.[0];
        if (this.selectedProduct) {
          this.getFeedbackOnProduct();
        }
      });
  }

  getFeedbackOnProduct(): void {
    if (this.selectedProduct) {
      this.feedbackForm.patchValue({
        finalisation_id: this.selectedProduct.id,
      });
    }
    this._componentService
      .getFinalisationConsumerFeedbacks(this.feedbackForm.value)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((error) => {
          console.error('Error fetching feedback for product:', error);
          return of({ code: 500, data: [] });
        })
      )
      .subscribe((response) => {
        this.consumerFeedbackList = response.data;
      });
  }

  updateAgeRange(): void {
    const startAge = this.feedbackForm.get('startAge')?.value;
    const endAge = this.feedbackForm.get('endAge')?.value;

    if (startAge !== null && endAge !== null) {
      if (startAge >= endAge) {
        this.feedbackForm.patchValue({ endAge: startAge + 1 });
      }
      this.startAgeOptions = this.ageOptions.filter((age) => age < endAge);
      this.endAgeOptions = this.ageOptions.filter((age) => age > startAge);
    } else if (startAge !== null) {
      this.endAgeOptions = this.ageOptions.filter((age) => age > startAge);
      this.startAgeOptions = this.ageOptions;
    } else if (endAge !== null) {
      this.startAgeOptions = this.ageOptions.filter((age) => age < endAge);
      this.endAgeOptions = this.ageOptions;
    } else {
      this.startAgeOptions = this.ageOptions;
      this.endAgeOptions = this.ageOptions;
    }

    const ageRange = (startAge && endAge) ? [startAge, endAge] : null;
    this.feedbackForm.patchValue({ age: ageRange });
    if ((startAge && endAge) || (!startAge && !endAge)) {
      this.getFeedbackOnProduct();
    }
  }

  resetAgeSelection(): void {
    this.feedbackForm.patchValue({ startAge: '', endAge: '', age: '' });
    this.startAgeOptions = this.ageOptions;
    this.endAgeOptions = this.ageOptions;
  }
}
