import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { CommonService } from 'src/app/components/common.service';
import { ComponentService } from 'src/app/components/component.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-ProductsForSale',
  templateUrl: './ProductsForSale.component.html',
  styleUrls: ['./ProductsForSale.component.scss'],
})
export class ProductsForSaleComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('carouselElement') carouselElement!: ElementRef;

  data!: any;
  info!: any;

  currentDate: Date = new Date();

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _router: Router,
    private readonly _loggingService: LoggingService,
    private readonly _componentService: ComponentService,
    private readonly _renderer: Renderer2,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getLastProductVendit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    const carousel = this.carouselElement.nativeElement;
    this._renderer.listen(carousel, 'slid.bs.carousel', (event: any) => {
      const activeIndex = event.to;
      const currentItem = this.data[activeIndex];
      this.info = [
        {
          status: currentItem.status,
          dayInSale: currentItem.dayInSale,
          rejected: currentItem.rejected
        }
      ];
    });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('/your-products', 18);
    this._router.navigate(['your-products']);
    const currentUrl = this._router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-doughnut-and-pie-chart',
    };
    lastValueFrom(this._loggingService.logClick(data));
  }

  getLastProductVendit(): void {
    this._componentService.getLastProductVendit()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.data = r.data;
        this.info = [
          {
            status: this.data[0].status,
            dayInSale: this.data[0].dayInSale,
            rejected: this.data[0].rejected,
          },
        ];
      });
  }
}
