<div class="transporter-home">
  <div class="row mx-0">
    <!-- Left container (container sinistra - main widgets) -->
    <div class="d-flex flex-column col-12 col-lg-8 gap-3 gap-lg-4 px-0 mb-3 mb-lg-0 ps-lg-0 pe-lg-4">
      <div class="most-used-activities-container">
        <app-most-used-activities [favoriteActivities]="favoriteActivities"></app-most-used-activities>
      </div>

      <div class="card bd-rad bg-color distr-lot" *ngIf="widget.includes('app-distributor-lots'); else noData">
        <div class="card-body">
          <app-distributor-lots [actor]="6"></app-distributor-lots>
        </div>
      </div>

      <div>
        <div class="bd-rad h-100" *ngIf="widget.includes('app-latestShipments'); else noData">
          <app-latestShipments [actor]="'distributor_id'"></app-latestShipments>
        </div>
      </div>

      <div class="card bd-rad bg-color arriving-dep-products" *ngIf="widget.includes('app-widget-arriving-departing-products'); else noData">
        <div class="card-body">
          <app-widget-arriving-departing-products></app-widget-arriving-departing-products>
        </div>
      </div>
    </div>

    <!-- Right container (container destra - Shipping Status e Feedback) -->
    <div class="d-flex flex-column col-12 col-lg-4 gap-3 gap-lg-4 px-0">
      <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-shipping-status'); else noData">
        <div class="card-body">
          <app-shipping-status></app-shipping-status>
        </div>
      </div>

      <div>
        <div class="card h-100 bd-rad bg-quantity pb-0">
          <div class="card-body">
            <app-batch-waste-used></app-batch-waste-used>
          </div>
        </div>
      </div>

      <div>
        <div class="card h-100 bd-rad bg-quantity pb-0">
          <div class="card-body">
            <app-actor-waste-used-widget></app-actor-waste-used-widget>
          </div>
        </div>
      </div>

      <div class="card bd-rad bg-feedback h-100" *ngIf="widget.includes('feedback'); else noData">
        <div class="card-body">
          <app-widget-feedback></app-widget-feedback>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noData>
    <div class="card bd-rad bg-no-data h-100">
      <div class="card-body">
        <app-no-data></app-no-data>
      </div>
    </div>
  </ng-template>
</div>