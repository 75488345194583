<div class="activity-type">
    <div class="row main-box mx-0">
        <div class="col-1 bg-left col-rel"
            [ngClass]="{ 'bg-left-lemon': report.product.category == 2, 'bg-left-orange': report.product.category == 1 }">
            <div class="box-image d-none d-lg-block">
                <img [src]="getIconPath(report.product.category)" alt="">
            </div>
        </div>
        <div class="col-11 bg-right"
            [ngClass]="{ 'bg-right-lemon': report.product.category == 2, 'bg-right-orange': report.product.category == 1 }">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <span class="title-card pe-3">{{report.activity}}</span>
                    <app-badge-blockchain *ngIf="report.is_verified"></app-badge-blockchain>
                </div>
                <button nz-button [nzSize]="size" *ngIf="!report.note" nzType="primary" class="button-add"
                    (click)="openModal(report, $event)" (keydown)="openModal(report, $event)">
                    +
                    <span class="d-none d-lg-inline">&nbsp;Aggiungi informazioni</span>
                </button>
                <button nz-button [nzSize]="size" *ngIf="report.note" nzType="primary" class="button-add"
                    (click)="openModal(report, $event)" (keydown)="openModal(report, $event)">
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                    <span class="d-none d-lg-inline">&nbsp;Modifica informazioni</span>
                </button>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-4">
                <div>
                    <ul style="list-style-type: none;" class="ps-0 mb-2" *ngFor="let act of activity_list">
                        <li>{{act.type}}</li>
                    </ul>
                    <ul style="list-style-type: none;" class="ps-0 mb-2" *ngIf="report.note">
                        <li>Note</li>
                    </ul>
                </div>
                <div>
                    <ul style="list-style-type: none;" class="ps-0 mb-2">
                        <li><span class="fw-500">{{(report.product.batch?.batch_unique_identifier ?
                                report.product.batch.batch_unique_identifier : 'Nessun lotto')}}</span></li>
                    </ul>
                    <ul style="list-style-type: none;" class="ps-0 mb-2">
                        <li><span class="fw-500">{{report.cultivar}}</span></li>
                    </ul>
                    <ul style="list-style-type: none;" class="ps-0 mb-2">
                        <li><span class="fw-500">{{report.causal}}</span></li>
                    </ul>
                    <ul style="list-style-type: none;" class="ps-0 mb-2">
                        <li><span class="fw-500">{{report.product.name}}</span></li>
                    </ul>
                    <ul style="list-style-type: none;" class="ps-0 mb-2">
                        <li><span class="fw-500">{{report.plot}}</span></li>
                    </ul>
                    <ul style="list-style-type: none;" class="ps-0 mb-2">
                        <li><span class="fw-500">{{report.amount}} {{ getUnitOfMeasure(report.activity)
                                }}</span></li>
                    </ul>
                    <ul style="list-style-type: none;" class="ps-0 mb-2">
                        <li><span class="fw-500">{{report.surface}} (ha)</span></li>
                    </ul>
                    <ul style="list-style-type: none;" class="ps-0 mb-2" *ngIf="report.note">
                        <li class="d-flex flex-row"><span class="pt-1 fw-500">{{report.note}}</span>
                            <div class="d-flex align-items-center ms-2">
                                <button type="button" class="btn btn-danger ms-1 px-1 py-0"
                                    (click)="removeNote(report.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-trash3" viewBox="0 0 16 16">
                                        <path
                                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODALE -->
<nz-modal [nzClosable]="false" [nzClassName]="'modal-add-activity'"
    [nzTitle]="((!report.note) ? 'Aggiungi' : 'Modifica')+' informazioni'" [nzOkText]="'Salva'"
    [(nzVisible)]="isVisibleActivityModal" (nzOnCancel)="closeModal()" (nzOnOk)="insertNote(content2?.id)">
    <ng-container *nzModalContent>
        <form [formGroup]="formReport">
            <div class="d-flex flex-rox">
                <div class="me-3 d-flex align-items-center"><img [src]="getIconPath(content2?.product.category)"
                        alt="cytrus">
                </div>
                <div class="d-flex flex-column">
                    <div class="mod-subtitle">Lotto</div>
                    <div class="mod-text">{{content2?.product.batch?.batch_unique_identifier ?
                        report.product.batch.batch_unique_identifier : 'Nessun lotto'}}</div>
                </div>
            </div>
            <div class="mod-subtitle mt-4">Attività</div>
            <div class="mod-text">{{content2?.activity}}</div>
            <div class="mod-subtitle mt-4">{{(!report.note) ? 'Aggiungi' : 'Modifica'}} note</div>
            <div class="mt-2">
                <textarea class="form-control bd-rad" formControlName="note" id="note" rows="7"></textarea>
            </div>
        </form>
    </ng-container>
</nz-modal>
