import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ComponentService } from 'src/app/components/component.service';

@Component({
  selector: 'app-DiscardOfTheDay',
  templateUrl: './DiscardOfTheDay.component.html',
  styleUrls: ['./DiscardOfTheDay.component.scss']
})
export class DiscardOfTheDayComponent implements OnInit, OnDestroy {
  data: any;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this._componentService.scartoDelGiorno()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        this.data = r?.data;
      });
  }

}
