import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ChartService } from 'src/app/components/charts/chart.service';

@Component({
  selector: 'app-consumed-energy',
  templateUrl: './consumed-energy.component.html',
  styleUrls: ['./consumed-energy.component.scss'],
})
export class ConsumedEnergyComponent implements OnInit, OnDestroy {
  selectedField!: string;

  prov: any;
  kvh: any;
  placeHolder: string = 'Seleziona una provincia';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService
  ) { }

  ngOnInit(): void {
    this.getTransformerMunicipality();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.getTransformerenergyData();
  }

  getTransformerMunicipality(): void {
    this._chartService.getTransformerMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        if (r.data.length > 0) {
          this.prov = r.data;
          this.selectedField = r.data[0]?.id;
          this.getTransformerenergyData();
        } else {
          this.placeHolder = 'Nessun dato';
        }
      });
  }

  getTransformerenergyData(): void {
    this._chartService
      .getTransformerenergyData(this.selectedField)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.kvh = r.data?.energetic_consumption;
      });
  }
}
