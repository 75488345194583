import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { ComponentService } from 'src/app/components/component.service';
import { BehaviorSubject, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { Utils } from 'src/app/shared/helpers/utils';
import { CommonService } from 'src/app/components/common.service';

@Component({
  selector: 'app-widget-arriving-departing-products',
  templateUrl: './widget-arriving-departing-products.component.html',
  styleUrls: ['./widget-arriving-departing-products.component.scss'],
})
export class WidgetArrivingDepartingProductsComponent implements OnInit, OnDestroy {
  data!: any;

  selectedValue: any = 'true';
  arriving: BehaviorSubject<string> = new BehaviorSubject<string>('Arrivo');
  loaded: boolean = false;
  cols: { label: string, isOnlyDesktop: boolean }[] = [];
  colsPartenza: string[] = [];
  role: any = localStorage.getItem('role') ?? 6;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _loggingService: LoggingService,
    private readonly router: Router,
    private readonly _componentService: ComponentService,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) {
    this.cols = [
      { label: 'Lotto', isOnlyDesktop: false },
      { label: this.arriving.value, isOnlyDesktop: false },
      { label: 'Azienda', isOnlyDesktop: true },
      { label: 'Prodotto', isOnlyDesktop: false },
      { label: 'In magazzino', isOnlyDesktop: true },
      { label: 'Quantitá', isOnlyDesktop: true }
    ];
    this.colsPartenza = [
      'Lotto',
      'Partenza',
      'Prodotto',
      'Quantitá',
      'Destinazione',
      'Mezzo'
    ];
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this._componentService
      .lastDistributoreShipping(this.selectedValue)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.loaded = false;
        this.data = r.data;
        this.cols[1] = (this.data[0]?.arriving) ? { label: 'Arrivo', isOnlyDesktop: false } : { label: 'Partenza', isOnlyDesktop: false };
        this.loaded = true;
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('widget-arriving-departing-products', 17);
    this.router.navigate(['/warehouse/' + this.role]);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-arriving-departing-products',
    };
    lastValueFrom(this._loggingService.logClick(data));
  }
}
