<div class="add-report-widget" (click)="showModal($event)" (keydown)="showModal($event)">
  <span class="plus">
    <img src="../../../../../../assets/img/add-button.svg" alt="" />
  </span>
  <span class="header-widget">
    Aggiungi report del giorno
  </span>
</div>

<!-- Modal -->
<nz-modal [nzClosable]="false" [nzClassName]="'modal-report'" [nzTitle]="'Report del giorno'"
  [nzOkLoading]="isOkLoading" [nzOkText]="'Invia'" [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <form [formGroup]="formReport">
      <div class="select-prod padding-b">
        <div class="modal-text marg-b">Seleziona il tuo prodotto</div>
        <div class="d-flex align-items-center">
          <span *ngFor="let prod of productsType"
            [ngClass]="{'selected': prod.selected, 'border-selected': prod.selected, 'border-to-select': !prod.selected,}"
            class="icon-to-select d-flex align-items-center justify-content-around"
            (click)="selectProductType(prod.id, $event)" (keydown)="selectProductType(prod.id, $event)">
            <img [src]="prod.path" alt="" />
          </span>
        </div>
      </div>

      <div class="select-lotto padding-b">
        <div class="modal-text marg-b">Seleziona il lotto per la quale vuoi fare il report</div>
        <div class="modal-select">
          <nz-select class="w-100" nzAllowClear nzPlaceHolder="Seleziona lotto" formControlName="lotto"
            [(ngModel)]="selectedValue">
            <nz-option *ngFor="let item of batchToSelect" [nzLabel]="item" [nzValue]="item"></nz-option>
          </nz-select>
        </div>
      </div>

      <div class="kg-insert padding-b">
        <div class="modal-text marg-b">Inserisci i kg venduti e quelli scartati</div>
        <div class="d-flex justify-content-between gap-2">
          <div class="flex-grow-1">
            <input class="form-control" type="number" formControlName="sold" placeholder="kg venduti" min="1">
          </div>
          <div class="flex-grow-1">
            <input class="form-control" type="number" formControlName="rejected" placeholder="kg scartati" min="0">
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</nz-modal>