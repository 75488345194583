<div class="distributor-lots">
  <div class="d-flex justify-content-between">
    <div>
      <h4 class="header-widget">I tuoi trattamenti</h4>
    </div>
    <div class="size-sel d-flex align-items-center gap-3">
      <nz-select class="custom-select placeholder-color" [nzPlaceHolder]="placeholder" [(ngModel)]="selectedField"
        (ngModelChange)="getMyBatch()">
        <nz-option *ngFor="let field of (fields)" [nzLabel]="field" [nzValue]="field">
          {{field}}</nz-option>
      </nz-select>
      <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
        <img src="/assets/img/arrow.svg" alt="">
      </span>
    </div>
  </div>

  <div class="mt-3 fs-12" *ngIf="form.value?.lotto">
    Lotto: <span class="lotto ms-2 px-3 py-1">{{form.value.lotto}}</span>
  </div>

  <div class="mt-2 card-content-list">
    <ng-container *ngFor="let item of status_card">
      <ng-container *ngIf="item.label && (item.date || item.data)">
        <div class="card-content" *ngIf="actor == 5">
          <div class="card-content__title">{{ item.type }}</div>
          <div class="card-content__body">
            <img class="card-img" [src]="item.img" alt="" />
            <div class="card-text">
              <div class="card-text__title">{{ item.label }}</div>
              <div class="card-text__content" *ngIf="item.date">{{ item.date | date:'dd/MM/yyyy' }}</div>
              <div class="card-text__content" *ngIf="item.data">{{ item.data }}</div>
            </div>
          </div>
        </div>
        <div class="card-content" [ngStyle]="{
          'background-image': 'url(' + item.img + ')',
          'background-position': item.type == 'Prodotto' ? 'left 10% bottom 18%' : 'bottom left',
          'background-repeat': 'no-repeat',
          'background-size': 'contain'
        }" *ngIf="actor != 5">
          <div class="card-content__title">{{ item.type }}</div>
          <div class="card-content__body card-content__body--other-card">
            <div class="card-text">
              <div class="card-text__title">{{ item.label }}</div>
              <div class="card-text__content" *ngIf="item.date">{{item.date | date:'dd/MM/yyyy' }}</div>
              <div class="card-text__content" *ngIf="item.data">{{ item.data }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>