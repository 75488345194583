import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  currentUser: BehaviorSubject<User | any> = new BehaviorSubject(null);

  getUserFeedback(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(
      `${environment.api}/feedbacks/user`,
      { params }
    );
  }
}
