<div class="discard-day">
  <div class="title">
    <h4 class="header-widget">Scarto del giorno</h4>
  </div>
  <div class="box-info">
    <div class="d-flex flex-column">
      <div class="box-info__title">Arance</div>
      <div class="box-info__value">
        {{data?.arancia ?? '0'}}<span>kg</span>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="box-info__title">Limoni</div>
      <div class="box-info__value">
        {{data?.limone ?? '0'}}<span>kg</span>
      </div>
    </div>
  </div>
</div>