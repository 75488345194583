import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor(private http: HttpClient) {}

  getPlantsWidgetData() {
    return this.http.get<any>(`${environment.api}/plantes`);
  }

  getproductionData(year: any) {
    return this.http.get<any>(
      `${environment.api}/widgets/production?year=${year}`
    );
  }

  getWaterData(town: any) {
    return this.http.get<any>(`${environment.api}/widgets/water/${town}`);
  }

  getSunData(town: any) {
    return this.http.get<any>(`${environment.api}/widgets/soil/${town}`);
  }

  getMunicipality() {
    return this.http.get<any>(`${environment.api}/municipalities/my`);
  }

  getMarketMunicipality() {
    return this.http.get<any>(`${environment.api}/municipalities/market`);
  }

  getMarketPriceData(town: any) {
    return this.http.get<any>(`${environment.api}/market-price/${town}`);
  }

  getMarketPriceDataFiltered(municipality_id: any) {
    return this.http.get<any>(
      `${environment.api}/market-prices/${municipality_id}/filtered`
    );
  }

  getAlertFms() {
    return this.http.get<any>(`${environment.api}/plants/alert`);
  }

  getTransformerMunicipality() {
    return this.http.get<any>(`${environment.api}/municipalities/trasformer`);
  }

  getTransformerWaterData(prov: any) {
    return this.http.get<any>(
      `${environment.api}/transformer-water-consumed/${prov}`
    );
  }

  getTransformerQuantityData(prov: any) {
    return this.http.get<any>(
      `${environment.api}/transformer-rejected-quantity/${prov}`
    );
  }

  getTransformerenergyData(prov: any) {
    return this.http.get<any>(
      `${environment.api}/energetic-consumption/${prov}`
    );
  }

  getTransformerinventoryStockData() {
    return this.http.get<any>(`${environment.api}/inventory-stocks`);
  }

  getTransformerproductsComingSoonData() {
    return this.http.get<any>(`${environment.api}/inventory-stocks/product/latest`);
  }

  getLatestTransformationData() {
    return this.http.get<any>(`${environment.api}/batch-transformation`);
  }

  getWidgetVenditoreInStockData() {
    return this.http.get<any>(`${environment.api}/giacency/point-vendit/warehouse`);
  }

  getproductionDataStatistics(year: any, plante_id?: any) {
    return this.http.get<any>(
      `${environment.api}/production-trend?year=${year}&plante_id=${plante_id}`
    );
  }

  getMunicipalityByProvince(province_label: string) {
    return this.http.get<any>(
      `${environment.api}/municipalities/provinces/${province_label}`
    );
  }

  getWasteInfoByUser(data: any) {
    return this.http.get<any>(
      `${environment.api}/batches/waste-graph?user_id=${data.user_id}&date=${data.date}&product_type=${data.productType}`
    );
  }
}
