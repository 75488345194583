import { Component, Input, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IWidget } from 'src/app/components/sidebar/Interfaces/IWidget';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-container1',
  templateUrl: './container1.component.html',
  styleUrls: ['./container1.component.scss'],
})
export class Container1Component implements OnInit, OnDestroy {
  @Input() widgetsFromPredict: [] = [];
  @Input() favoriteActivities: IWidget[] | null = null;

  widget: any[] = new Array();
  is_dashboard: boolean = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _authService: AuthService,
    private readonly _adminService: AdminService,
    private readonly _cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._authService.getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        let role_id = localStorage.getItem('role_id');
        this.getAllWidget(role_id);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAllWidget(role_id: any): void {
    if (this.widgetsFromPredict.length === 0) {
      this._adminService.getAllwidgetRole(role_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((r) => {
          r.data.forEach((element: { value: any }) => {
            this.widget.push(element.value);
          });
          this._cdr.detectChanges();
        });
    } else {
      this.widget = Object.values(this.widgetsFromPredict);
      this._cdr.detectChanges();
    }
  }
}
