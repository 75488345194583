<div class="statistics-market-prices" *ngIf="municipalityId">
  <div class="box-header">
    <div class="box-header__title">
      Prezzi di mercato
    </div>
    <div class="box-header__filter-cta">
      <a href="javascript:void(0)" (click)="toggleCollapsedForMobileFiltering($event)"
        (keydown)="toggleCollapsedForMobileFiltering($event)">
        Filtra
        <i class="icon-custom settings-orange mt-2"></i>
      </a>
    </div>
  </div>
  <div class="box-mobile-filter" [class.box-mobile-filter--open]="!isToggleCollapsedForMobileFiltering">
    <div class="filter-title">Filtra</div>
    <form class="box-filter-input" [formGroup]="filtersForm">
      <nz-select class="w-100" formControlName="month" nzPlaceHolder="Mese">
        <nz-option *ngFor="let month of months" [nzLabel]="month.label" [nzValue]="month.value"></nz-option>
      </nz-select>
      <nz-select class="w-100" formControlName="year" [nzPlaceHolder]="selectedYear">
        <nz-option *ngFor="let year of years" [nzLabel]="year.label" [nzValue]="year.value"></nz-option>
      </nz-select>
      <nz-select class="w-100" formControlName="municipality_id" nzPlaceHolder="Comune">
        <nz-option *ngFor="let municipality of municipalities" [nzLabel]="municipality.label"
          [nzValue]="municipality.id">
        </nz-option>
      </nz-select>
    </form>
  </div>
  <div class="white-bordered-container">
    <div class="d-flex header-graph justify-content-between align-items-center header-market-prices">
      <div class="d-none d-lg-block">
        <h4 class="header-graph-title">Prezzi di mercato</h4>
      </div>
      <div class="d-flex align-items-center gap-5">
        <div class="d-flex flex-wrap flex-row align-items-center">
          <span class="span-filter"><img src="assets/icon/Ellipse 2.svg" alt="arancio chiaro"> Arance</span>
          <span><img src="assets/icon/Ellipse 1.svg" alt="giallino chiaro"> Limoni</span>
        </div>
        <form class="d-none d-lg-block" [formGroup]="filtersForm">
          <div class="d-flex">
            <nz-select id="left-select-month" class="custom-select col-4 " formControlName="month" nzPlaceHolder="Mese">
              <nz-option *ngFor="let month of months" [nzLabel]="month.label" [nzValue]="month.value"></nz-option>
            </nz-select>
            <nz-select id="middle-select-year" class="custom-select col-4" formControlName="year"
              [nzPlaceHolder]="selectedYear">
              <nz-option *ngFor="let year of years" [nzLabel]="year.label" [nzValue]="year.value"></nz-option>
            </nz-select>
            <nz-select class="custom-select col-4 " formControlName="municipality_id" nzPlaceHolder="Comune">
              <nz-option *ngFor="let municipality of municipalities" [nzLabel]="municipality.label"
                [nzValue]="municipality.id">
              </nz-option>
            </nz-select>
          </div>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-center market-graphic" *ngIf="showWidget">
      <canvas id="lineChart" #baseChars baseChart [type]="'line'" [options]="lineChartOptions"
        [legend]="marketPriceLegend" [data]="lineChartData">
      </canvas>
    </div>

    <section class="selected-month d-none d-lg-block">
      <div class="title-selected-month">
        <p>{{ selectedMonthLabel }}&nbsp;{{ filtersForm.get('year')?.value }}</p>
      </div>

      <div>
        <form [formGroup]="filtersForm">
          <input type="hidden" formControlName="category">
          <div class="filter-buttons">
            <div *ngFor="let filter of filterOptions" class="filter-button">
              <button type="button" (click)="selectFilter(filter, $event)" (keydown)="selectFilter(filter, $event)"
                [ngClass]="{'active-filter': filter.active, 'inactive-filter': !filter.active}">
                <img *ngIf="filter.image" [src]="'assets/icon/' + filter.image + '.svg'" alt="{{filter.label}}">
                {{filter.label}}
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="table-responsive">
        <table class="table custom-table">
          <thead>
            <tr>
              <th [ngClass]="{'th-border': fillMarketData}" *ngFor="let column of columns">{{ column }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of marketData">
              <td>{{ translateProductName(item.name) }}</td>
              <td>{{ item.avg_price | number: '1.2-2' }} €</td>
              <td>{{ item.max_price | number: '1.2-2' }} €</td>
              <td>{{ item.min_price | number: '1.2-2' }} €</td>
            </tr>
          </tbody>
        </table>
      </div>

    </section>
  </div>

  <!-- begin: Mobile/Tablet prod list -->
  <div class="list-prod-mobile" *ngIf="marketData?.length">
    <div class="list-prod-mobile__title">
      <p>{{ selectedMonthLabel }}&nbsp;{{ filtersForm.get('year')?.value }}</p>
    </div>
    <div class="list-prod-mobile__box-select-type">
      <form [formGroup]="filtersForm">
        <input type="hidden" formControlName="category">
        <div class="filter-buttons">
          <div *ngFor="let filter of filterOptions" class="filter-button">
            <button type="button" (click)="selectFilter(filter, $event)" (keydown)="selectFilter(filter, $event)"
              [ngClass]="{'active-filter': filter.active, 'inactive-filter': !filter.active}">
              <img *ngIf="filter.image" [src]="'assets/icon/' + filter.image + '.svg'" alt="{{filter.label}}">
              {{filter.label}}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="list-prod-mobile__item-list" *ngFor="let data of marketData">
      <div class="header-item">
        <p class="description">Prodotto</p>
        <p class="title">{{translateProductName(data.name)}}</p>
      </div>
      <div class="content-item">
        <div class="content-item__row">
          <div>
            <div class="row-title">Prezzo medio mensile</div>
            <div class="row-value">{{data.avg_price | number: '1.2-2'}} €</div>
          </div>
        </div>
        <div class="content-item__row">
          <div>
            <div class="row-title">Prezzo massimo mensile</div>
            <div class="row-value">{{data.max_price | number: '1.2-2'}} €</div>
          </div>
        </div>
        <div class="content-item__row">
          <div>
            <div class="row-title">Prezzo minimo mensile</div>
            <div class="row-value">{{data.min_price | number: '1.2-2'}} €</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end: Mobile/Tablet prod list -->
</div>