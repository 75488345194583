import { NgModule } from '@angular/core';
import { ComponentsRoutingModule } from './components-routing.module';
import { SharedModule } from '../shared/shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CollapseComponent } from './collapse/collapse.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BaseComponent } from './base/base.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from '../auth/login/login.component';
import { RegisterComponent } from '../auth/register/register.component';
import { RegistrationDoneComponent } from '../auth/registration-done/registration-done.component';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { ProductsComponent } from './products/products.component';
import { ProfileComponent } from './profile/profile.component';
import { CardComponent } from './card/card.component';
import { SeeAllComponent } from './see-all/see-all.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackListComponent } from './feedback-list/feedback-list.component';
import { ShipmentTrackingComponent } from './shipment-tracking/shipment-tracking.component';
import { LotHistoryComponent } from './lotHistory/lotHistory.component';
import { LotHistoryTimelineComponent } from './lotHistoryTimeline/lotHistoryTimeline.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { HistoricalWeatherComponent } from './historical-weather/historical-weather/historical-weather.component';
import { WeatherDayCardComponent } from './historical-weather/weather-day-card/weather-day-card.component';
import { WeatherInstanceCardComponent } from './historical-weather/weather-instance-card/weather-instance-card.component';
import { LotHistoryMobileComponent } from './lotHistoryMobile/lotHistoryMobile.component';
import { ConsumerProductsComponent } from '../modules/producer/components/consumerProducts/consumerProducts.component';
import { StatisticsMarketPricesComponent } from './statistics-market-prices/statistics-market-prices.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { UserProfileComponent } from './userProfile/userProfile.component';
import { MySystemTransformerComponent } from './mySystemTransformer/mySystemTransformer.component';
import { MyPlantationsComponent } from './myPlantations/myPlantations.component';
import { ProducerModule } from '../modules/producer/producer.module';
import { TrasformerModule } from '../modules/trasformer/trasformer.module';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { ProductionRendComponent } from './production-rend/production-rend.component';
import { FeedbackConsumerComponent } from './feedback-list/feedback-consumer/feedback-consumer/feedback-consumer.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    CollapseComponent,
    BreadcrumbsComponent,
    BaseComponent,
    DashboardComponent,
    LoginComponent,
    RegisterComponent,
    RegistrationDoneComponent,
    PieChartComponent,
    ProductsComponent,
    ProfileComponent,
    CardComponent,
    SeeAllComponent,
    FeedbackComponent,
    FeedbackListComponent,
    ShipmentTrackingComponent,
    LotHistoryComponent,
    LotHistoryTimelineComponent,
    StatisticsComponent,
    HistoricalWeatherComponent,
    WeatherDayCardComponent,
    WeatherInstanceCardComponent,
    LotHistoryMobileComponent,
    ConsumerProductsComponent,
    StatisticsMarketPricesComponent,
    WarehouseComponent,
    UserProfileComponent,
    MySystemTransformerComponent,
    MyPlantationsComponent,
    ChatbotComponent,
    ProductionRendComponent,
    FeedbackConsumerComponent
  ],
  imports: [
    ComponentsRoutingModule,
    SharedModule,
    ProducerModule,
    TrasformerModule,
    // VenditoreModule,
    // TransporterModule,
    // ConsumerModule,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    CollapseComponent,
    BreadcrumbsComponent,
    BaseComponent,
    CardComponent,
  ],
})
export class ComponentsModule {}
