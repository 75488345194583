import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { Subject, takeUntil } from 'rxjs';
import { ChartService } from 'src/app/components/charts/chart.service';
import { ComponentService } from 'src/app/components/component.service';
import { IActor } from '../../IActor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMonthDataNoOptional } from 'src/app/components/historical-weather/interfaces/IMonthData';
import * as moment from 'moment';
import { IExtendedChartDataset } from '../../IExtendedChartSet';

@Component({
  selector: 'app-actor-waste-used-page',
  templateUrl: './actor-waste-used-page.component.html',
  styleUrls: ['./actor-waste-used-page.component.scss'],
})
export class ActorWasteUsedPageComponent implements OnInit, OnDestroy {
  @ViewChild('tooltipTemplate') tooltipTemplate!: ElementRef;

  actorForm: FormGroup;
  lineChartLegend: boolean = false;
  showChart: boolean = false;
  actorOptionsArray: IActor[] | null = null;
  months: IMonthDataNoOptional[] = [];
  productType: any[] = [
    { label: 'Tutti', value: null },
    { label: 'Arance', value: '1' },
    { label: 'Limoni', value: '2' },
  ];

  lineChartData: ChartConfiguration<'line'>['data'] = this.initializeLineChartData();
  lineChartOptions: ChartOptions<'line'> = this.initializeLineChartOptions();

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly _chartService: ChartService,
    private readonly _formBuilder: FormBuilder
  ) {
    this.actorForm = this.initializeForm();
  }

  ngOnInit(): void {
    this.months = this.generateMonths();
    this.setCurrentMonthInForm();
    this.getLinkedUsers();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeForm(): FormGroup {
    return this._formBuilder.group({
      date: [null, Validators.required],
      user_id: [null],
      productType: [null],
    });
  }

  initializeLineChartData(): ChartConfiguration<'line'>['data'] {
    return {
      labels: [
        'Prima settimana',
        'Seconda settimana',
        'Terza settimana',
        'Quarta settimana',
      ],
      datasets: [
        {
          data: [],
          label: 'Arance',
          fill: false,
          tension: 0.5,
          borderColor: '#EF7F00',
          pointRadius: 0,
          tooltips: [],
        } as IExtendedChartDataset,
        {
          data: [],
          label: 'Limoni',
          fill: false,
          tension: 0.5,
          borderColor: '#FAC36A',
          pointRadius: 0,
          tooltips: [],
        } as IExtendedChartDataset,
      ],
    };
  }

  initializeLineChartOptions(): ChartOptions<'line'> {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: this.lineChartLegend,
        },
        tooltip: {
          enabled: true,
          mode: 'nearest',
          position: 'nearest',
          intersect: false,
          callbacks: {
            label: (context) => {
              const dataset = context.dataset as IExtendedChartDataset;
              const index = context.dataIndex;
              const quantity = dataset.tooltips?.[index] ?? 'N/A';
              const percentage = context.raw;
              return `${context.dataset.label}: ${percentage}% - Quantità: ${quantity} kg`;
            },
            afterBody: () => {
              return ``; // da determinare se necessario un messaggio
            },
          },
          caretSize: 5,
          cornerRadius: 4,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          titleFont: {
            size: 14,
            weight: 'bold',
          },
          bodyFont: {
            size: 12,
            weight: 'normal',
          },
          padding: 10,
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
    };
  }

  getWasteInfoByUser(): void {
    this._chartService
      .getWasteInfoByUser(this.actorForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => this.handleWasteResponse(response));
  }

  handleWasteResponse(response: any): void {
    if (response.code === 200) {
      const chartData = response.data.lineChartData;
      const selectedProductType = this.actorForm.get('productType')?.value;
      const filteredDatasets: IExtendedChartDataset[] = [];

      if (!selectedProductType || selectedProductType === '1') {
        filteredDatasets.push({
          label: 'Arance',
          data: chartData.datasets.orangesData,
          tooltips: chartData.datasets.orangesTooltip,
          fill: false,
          tension: 0.5,
          borderColor: '#EF7F00',
          pointRadius: 0,
        });
      }

      if (!selectedProductType || selectedProductType === '2') {
        filteredDatasets.push({
          label: 'Limoni',
          data: chartData.datasets.lemonsData,
          tooltips: chartData.datasets.lemonsTooltip,
          fill: false,
          tension: 0.5,
          borderColor: '#FAC36A',
          pointRadius: 0,
        });
      }

      this.lineChartData = {
        labels: chartData.labels,
        datasets: filteredDatasets,
      };
    } else {
      this.lineChartData = this.initializeLineChartData();
      this.lineChartData.labels = ['Nessun dato'];
    }
    this.showChart = true;
  }

  getLinkedUsers(): void {
    this._componentService
      .getLinkedUsers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => this.handleLinkedUsersResponse(response));
  }

  handleLinkedUsersResponse(response: any): void {
    if (response.code === 200) {
      this.actorOptionsArray = response.data;
      if (this.actorOptionsArray?.length) {
        this.actorForm.patchValue({ user_id: this.actorOptionsArray[0].id });
        this.getWasteInfoByUser();
      }
    } else {
      this.actorOptionsArray = null;
    }
  }

  generateMonths(): IMonthDataNoOptional[] {
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const months: IMonthDataNoOptional[] = [];

    for (let i = 0; i <= currentMonth; i++) {
      months.push(this.createMonth(currentYear, i));
    }

    for (let i = currentMonth + 1; i < 12; i++) {
      months.push(this.createMonth(currentYear - 1, i));
    }

    return months;
  }

  createMonth(year: number, monthIndex: number): IMonthDataNoOptional {
    return {
      label: moment().locale('it').year(year).month(monthIndex).format('MMMM'),
      value: moment().year(year).month(monthIndex).format('YYYY-MM'),
    };
  }

  setCurrentMonthInForm(): void {
    this.actorForm.patchValue({ date: moment().format('YYYY-MM') });
  }

  capitalizeFirstLetter(label: string): string {
    return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
  }
}
