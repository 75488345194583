<div class="warehouse-container">
  <div class="header">
    <div class="title">Magazzino</div>
    <div class="select-warehouse select-warehouse-cont d-flex align-items-center justify-content-between">
      <span class="strong-span">Seleziona:</span>
      <div class="d-flex align-items-center justify-content-end">
        <nz-select class="wide-select" nzPlaceHolder="Anno" nzBorderless [(ngModel)]="selectedYear"
          (ngModelChange)="getMonths()">
          <nz-option [nzValue]="year" [nzLabel]="year" *ngFor="let year of years"></nz-option>
        </nz-select>
        <nz-select class="wide-select" nzPlaceHolder="Mese" nzBorderless [(ngModel)]="selectedMonth"
          (ngModelChange)="getInfoInStock()">
          <nz-option [nzValue]="month.key" [nzLabel]="month.value" *ngFor="let month of months"></nz-option>
        </nz-select>
      </div>
    </div>
  </div>

  <div class="selected-date mb-3" *ngIf="stringMonth(selectedMonth)">
    {{ stringMonth(selectedMonth) }} {{selectedYear}}
  </div>
  <div class="widgets-container d-flex flex-column flex-lg-row">
    <div class="widget-left">
      <div class="chart" *ngIf="loadWidget">
        <canvas id="doughnutChart" baseChart [data]="doughnutChartData" [type]="'doughnut'"
          [options]="doughnutChartOptions" [plugins]="doughnutChartPlugins">
        </canvas>
      </div>
      <div class="legend-chart">
        <div class="legend" *ngFor="let legend of chartLegend">
          <div class="d-flex align-items-center">
            <div class="circle" [ngStyle]="{ 'background-color': legend.color }"></div>
            <div class="label">{{legend.label}}</div>
          </div>
          <div class="quantity">{{ legend.quantity | number:'1.2-2' }}% dello spazio occupato</div>
        </div>
      </div>
    </div>
    <div class="widget-right">
      <div class="quantity-container quantity-container-color-in d-flex align-items-center">
        <div class="arrow-icon">
          <img src="../../../assets/img/download-icon.svg" alt="">
        </div>
        <div class="d-flex flex-column">
          <div class="label">Totale entrate del mese</div>
          <div class="quantity">{{entrance ?? '0'}} kg</div>
        </div>
      </div>
      <div class="quantity-container quantity-container-color-out d-flex align-items-center">
        <div class="arrow-icon">
          <img src="../../../assets/img/upload-icon.svg" alt="">
        </div>
        <div class="d-flex flex-column">
          <div class="label">Totale uscite del mese</div>
          <div class="quantity">{{exit ?? '0'}} kg</div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-container" *ngIf="data?.length">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="table-title">Lotti in magazzino</div>
      <div class="table-legend">
        <div class="d-flex gap-2">
          <img src="../../../assets/img/arrow-down-green.svg" alt="Ingresso" />
          <div>Ingresso</div>
        </div>
        <div class="d-flex gap-2">
          <img src="../../../assets/img/arrow-up-orange.svg" alt="Uscita" />
          <div>Uscita</div>
        </div>
      </div>
    </div>

    <div class="table-cont">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="font-title" *ngFor="let col of cols">{{col}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <td class="text-style-big padd">
              <div class="d-flex gap-2">
                <img src="../../../assets/img/arrow-up-orange.svg" alt="Uscita" *ngIf="item.is_requesting" />
                <img src="../../../assets/img/arrow-down-green.svg" alt="Ingresso" *ngIf="!item.is_requesting" />
                <div class="d-flex flex-column">
                  <div class="font-title">{{item.batch ?? 'Nessun dato'}}</div>
                  <div class="text-style-medium" *ngIf="item.type">{{item.type}}</div>
                </div>
              </div>
            </td>
            <td class="text-style-medium">{{item.producer ?? 'Nessun dato'}}</td>
            <td class="text-style-medium">{{item.giacency ?? '0'}} giorni</td>
            <td class="text-style-medium">{{item.quantity ?? '0'}} kg</td>
            <td>
              <div class="d-flex flex-column">
                <div class="text-style-medium">
                  <span>Arrivo:</span>&nbsp;{{item.arriving ?? 'nessun dato'}}
                </div>
                <div class="text-style-medium">
                  <span>Uscita:</span>&nbsp;{{item.shipping ?? 'non definita'}}
                </div>
              </div>
            </td>
            <td class="text-style-medium">{{item.destination ?? 'Nessun dato'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- begin: Mobile/Tablet warehouse lot -->
  <div class="warehouse-lot-mobile" *ngIf="data?.length">
    <div class="warehouse-lot-mobile__header">
      <div class="title">Lotti in magazzino</div>
      <div class="legend-list">
        <div class="d-flex gap-2">
          <img src="../../../assets/img/arrow-down-green.svg" alt="Ingresso" />
          <div>Ingresso</div>
        </div>
        <div class="d-flex gap-2">
          <img src="../../../assets/img/arrow-up-orange.svg" alt="Uscita" />
          <div>Uscita</div>
        </div>
      </div>
    </div>
    <div class="warehouse-lot-mobile__item-list" *ngFor="let item of data; let i = index">
      <div class="header-list">
        <div class="header-list__box-title">
          <p class="description">Lotto</p>
          <p class="title">
            <img class="mb-1 me-2" src="../../../assets/img/arrow-up-orange.svg" alt="Uscita" *ngIf="item.is_requesting" />
            <img class="mb-1 me-2" src="../../../assets/img/arrow-down-green.svg" alt="Ingresso" *ngIf="!item.is_requesting" />
            {{item.batch ?? 'Nessun dato'}}
          </p>
        </div>
        <div class="header-list__box-other-title" *ngIf="item.type">
          <p class="subtitle">
            {{item.type}}
          </p>
        </div>
      </div>
      <div class="content-item">
        <div class="content-item__row">
          <div>
            <div class="row-title">Produttore</div>
            <div class="row-value">{{item.producer ?? 'Nessun dato'}}</div>
          </div>
        </div>
        <div class="content-item__row">
          <div>
            <div class="row-title">Giacenza</div>
            <div class="row-value">{{item.giacency ?? '0'}} giorni</div>
          </div>
        </div>
        <div class="content-item__row">
          <div>
            <div class="row-title">Quantità</div>
            <div class="row-value">{{item.quantity ?? '0'}} kg</div>
          </div>
        </div>
        <div class="content-item__row">
          <div>
            <div class="row-title">Date di ingresso e uscita</div>
            <div class="row-value">
              <p>
                <span>Arrivo:</span>&nbsp;{{item.arriving ?? 'nessun dato'}}
              </p>
              <p>
                <span>Uscita:</span>&nbsp;{{item.shipping ?? 'non definita'}}
              </p>
            </div>
          </div>
        </div>
        <div class="content-item__row">
          <div>
            <div class="row-title">Destinazione</div>
            <div class="row-value">{{item.destination ?? 'Nessun dato'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end: Mobile/Tablet warehouse lot -->
</div>