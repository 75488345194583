import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ComponentService } from '../../component.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { Router } from '@angular/router';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-actor-waste-used-widget',
  templateUrl: 'actor-waste-used-widget.component.html',
  styleUrls: ['actor-waste-used-widget.component.scss'],
})
export class ActorWasteUsedWidgetComponent implements OnInit, OnDestroy {
  wasteInfos: any;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly _utils: Utils,
    private readonly _router: Router,
    private readonly _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getWasteAverage();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getWasteInfoByActor(): void {
    this._componentService
      .getWasteInfoByActor()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.data) {
          this.wasteInfos = response.data;
        }
      });
  }

  getWasteAverage(): void {
    this._componentService
      .getWasteAverage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.data) {
          this.wasteInfos = response.data;
        }
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('/actor-waste-used', 31);
    this._router.navigate(['actor-waste-used-page']);
  }
}
