import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentService } from 'src/app/components/component.service';
import { toast } from 'src/app/app.component';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';

@Component({
  selector: 'app-addReport',
  templateUrl: './addReport.component.html',
  styleUrls: ['./addReport.component.scss'],
})
export class AddReportComponent implements OnInit, OnDestroy {
  formReport!: FormGroup;
  batchToSelect!: any;

  isVisible: boolean = false;
  isOkLoading: boolean = false;
  selectedValue: any = null;
  type: any;
  productsType: any[] = [
    {
      id: 1,
      path: '../../../../../../assets/icon/modal-orange-icon.svg',
      selected: false,
    },
    {
      id: 2,
      path: '../../../../../../assets/icon/modal-lemon-icon.svg',
      selected: false,
    },
  ];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _componentService: ComponentService,
    private readonly _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.formReport = this._fb.group({
      lotto: [null, Validators.required],
      sold: [null, [Validators.required, Validators.min(1)]],
      rejected: [null, [Validators.required, Validators.min(0)]],
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showModal(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.isVisible = true;
  }

  handleOk(): void {
    if (this.formReport.valid && this.type) {
      let data = {
        type: this.type,
        lotto: this.formReport.value.lotto,
        sold: this.formReport.value.sold,
        rejected: this.formReport.value.rejected,
      };

      this.isOkLoading = true;
      lastValueFrom(this._componentService.storeDailyReport(data))
        .then((response: any) => {
          toast.fire({
            title: 'Report aggiunto',
            text: 'Il report del giorno è stato aggiunto con successo',
            icon: 'success',
          });
          this._componentService.triggerClickEvent();
          this.formReport.reset();
          this.isVisible = false;
        })
        .catch((errResponse: any) => {
          toast.fire({
            title: 'Errore',
            text: `${
              errResponse?.error ?? "Errore durante l'inserimento del report"
            }`,
            icon: 'error',
          });
        })
        .finally(() => (this.isOkLoading = false));
    } else {
      toast.fire({
        title: 'Campi mancanti',
        text: 'Compila tutti i campi',
        icon: 'error',
      });
    }
  }

  handleCancel(): void {
    this.formReport.reset();
    this.isVisible = false;
  }

  getMyBatchOnGiacencyPoint(): void {
    this.selectedValue = null;
    this._componentService
      .getMyBatchOnGiacencyPoint(this.type)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.batchToSelect = r.data;
      });
  }

  selectProductType(id: number, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.productsType.forEach((p) => (p.selected = false));
    const product = this.productsType.find((p) => p.id === id);
    if (product) {
      product.selected = true;
      this.type = product.id;
      this.getMyBatchOnGiacencyPoint();
    }
  }
}
